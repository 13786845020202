import React from 'react'
import { Button, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'

export const flags = {
  fr: '🇫🇷',
  uk: '🇬🇧',
  de: '🇩🇪',
  it: '🇮🇹',
  es: '🇪🇸',
}
const AmazonAPI = () => {
  return (
    <Container fluid>
      <CardBody
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          marginTop: 150,
        }}
      >
        <Row style={{ margin: 30 }}>
          <CardTitle className="h5 mb-4">
            L'API AMAZON CONNECTER / DÉCONNECTER
          </CardTitle>
        </Row>
        <Row>
          <Col>
            <Button>Connexion</Button>
          </Col>
          <Col>
            {Object.keys(flags).map((country) => (
              <Row key={`${country}flag`}>{flags[country]}</Row>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Container>
  )
}

export { AmazonAPI }
