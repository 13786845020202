// Include Both Helper File with needed methods
// import { firebaseAuth } from '../../../helpers/firebase'
import { AnyCnameRecord } from 'dns'
import * as api from '../../../data'
import {
  postFakeRegister,
  postJwtRegister,
} from '../../../helpers/fakebackend_helper'

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
} from './reducer'

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
export const registerUser =
  (user: any, navigate?: any) => async (dispatch: any) => {
    try {
      const response = postFakeRegister(user)

      const data: any = await response.data

      dispatch(registerUserSuccessful(data))
      navigate('/login')
    } catch (error: any) {
      console.log('registerUser error', { error })

      dispatch(
        registerUserFailed(
          error || "Erreur pendant l'inscription. Veuillez réessayer plus tard."
        )
      )
    }
  }

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange()
    return response
  } catch (error) {
    return error
  }
}

export const apiError = () => {
  try {
    const response = apiErrorChange('')
    return response
  } catch (error) {
    return error
  }
}
