/* eslint-disable max-len */
// import React, { useState } from 'react'
import toastr from 'toastr'
import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import * as Store from 'slices'

import { useSelector, useDispatch } from 'react-redux'
import { user } from 'slices/auth/login/reducer'
import { CardAcquisitionComponent } from 'Components/CardAcquisitionComponent/CardAcquisitionComponent'
import { confirmAlert } from 'react-confirm-alert'
import * as data from 'data'
import { PriceTable } from 'pages/PriceTable/PriceTable'
import CardPricing from './card-pricing'

const Plan = () => {
  const params = new URLSearchParams(window.location.search)
  const redirectStatus = params.get('redirect_status')

  useEffect(() => {
    if (!redirectStatus) {
      return
    }
    if (redirectStatus === 'succeeded') {
      toastr.success('Plan updated successfully!', 'Success')
    } else {
      toastr.error('Error updating plan!', 'Error')
    }
  }, [redirectStatus])

  // // meta title
  document.title = 'Pricing'

  return (
    <div className="page-content">
      <Container fluid>
        <PriceTable />
      </Container>
    </div>
  )
}
export { Plan }
