import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Store from 'slices'
import * as data from 'data'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}
const PriceTable = () => {
  const [clientSecret, setClientSecret] = useState('')
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    data.subscription
      .get('session')
      .then((response) => {
        setClientSecret(response.data.client_secret)
        console.log('response', response)
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setLoaded(true)
      })
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_TABLE_PRICE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
      customer-session-client-secret={clientSecret}
    />
  )
}
export { PriceTable }
