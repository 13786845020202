export const redirectRoutes = {
  REGISTER: '/register',
  SIGNUP: '/signup',
  LOGIN: '/login',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  UPDATE_USER: '/update-user',
  PLAN: '/plan',
  SUPPLIER: '/suppliers',
} as const
