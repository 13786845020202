// import { EntityAPI } from '../helpers/EntityApi/EntityApi'

import { APIClient } from '../helpers/api_helper'

export type TUserLanding = {
  id?: string
  email: string
  firstName: string
  lastName: string
}
class UserLanding extends APIClient<TUserLanding> {}
export { UserLanding }
