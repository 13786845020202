import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import { TUser } from 'data/UserEntity'
import * as data from 'data'

export const reducerName = 'users'
const usersSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = usersSlice
export { actions, reducer, selectors }

export const fetchUsers = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    const res = await data.user.get('')

    dispatch(
      actions.fetchSuccess({
        data: res.data,
        loadMore: false,
      })
    )
  } catch (error) {
    dispatch(actions.fetchError())
  }
}

export const toggleAdmin =
  (id: string, isAdmin: boolean = false) =>
  async (dispatch: any) => {
    dispatch(actions.editRequest())
    try {
      const roles = isAdmin ? ['USER', 'ADMIN'] : ['USER']
      await data.user.put(id, { roles })
      const user = await data.user.get(id)
      if (!user.data) throw new Error('User not found')
      dispatch(actions.editSuccess(user.data))
      return true
    } catch (error) {
      dispatch(actions.editError())
      return false
    }
  }

export const deleteUser = (userId: string) => {
  return async (dispatch: any) => {
    dispatch(actions.deleteRequest())
    try {
      await data.user.delete(userId)
      dispatch(actions.deleteSuccess(userId))
    } catch (error) {
      dispatch(actions.deleteError())
    }
  }
}

export const createUser = (user: TUser) => {
  return async (dispatch: any) => {
    dispatch(actions.editRequest())
    try {
      // const isUserExist = await data.user.getOneBy('authId', user.authId)
      // if (isUserExist) throw new Error('User already exist')
      const res = await data.user.create('', user)
      dispatch(actions.editSuccess(res))
    } catch (error) {
      dispatch(actions.editError())
    }
  }
}

export const update = (id: string, user: Partial<TUser>) => {
  return async (dispatch: any) => {
    dispatch(actions.editRequest())
    try {
      const updated = await data.user.update(id, user)
      console.log('updated', updated)

      const updatedUser = await data.user.get(id)
      if (!updatedUser) throw new Error('User not found')
      dispatch(actions.editSuccess(updatedUser))
      return true
    } catch (error) {
      dispatch(actions.editError())
      return false
    }
  }
}
