import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap'
import * as data from 'data'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import toastr from 'toastr'
import { SupplierRow } from './SupplierRow'

const Supplier = () => {
  const [modal, setModal] = useState(false)
  const [processingData, setProcessingData] = useState<any>({})
  const isUserAdmin = useSelector(Store.login.selectors.isAdmin)

  const suppliers: string[] = useSelector(
    Store.suppliers.selectors.getIdCollection
  ) as any
  const dispatch = useDispatch()

  const pullData = (force = false) => {
    dispatch(Store.suppliers.fetchSuppliers(force) as any)
  }
  const forceRefresh = () => {
    pullData(true)
  }
  const isFetching = useSelector(Store.suppliers.selectors.isLoading)
  useEffect(() => {
    pullData(false)
    data.supplier
      .get('processing')
      .then((res) => {
        setProcessingData(res.data)
      })
      .catch((err) => {
        if (
          err === 'Invalid credentials' ||
          err === 'Wrong authentication token'
        ) {
          dispatch(Store.login.logoutUser() as any)
        }
        console.error(err)
      })
  }, [])

  const refreshOctoparseData = async () => {
    const isOk = await dispatch(Store.suppliers.refreshOctoparseData() as any)
    if (isOk) {
      toastr.success('Les données ont été mises à jour', 'Fournisseurs')
    } else {
      toastr.error('Une erreur est survenue', 'Fournisseurs')
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        {localStorage.getItem('disclaimer') && (
          <Alert
            style={{
              zIndex: 1000000,
              alignSelf: 'center',
              textAlign: 'center',
            }}
            color="danger"
          >
            <h1>{localStorage.getItem('disclaimer')}</h1>
          </Alert>
        )}
        <h1>Supplier</h1>
        {isUserAdmin && (
          <div className="hstack gap-3 p-3">
            <button type="button" className="btn btn-primary">
              Ajouter un supplier
            </button>
            <div className="vr" />
            <button
              type="button"
              disabled={isFetching}
              onClick={refreshOctoparseData}
              className="btn btn-outline-info"
            >
              {isFetching && (
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              )}{' '}
              Refresh Octoparse
            </button>
            <div className="vr" />
            <button
              type="button"
              onClick={forceRefresh}
              className="btn btn-outline-info"
            >
              Force Refresh
            </button>
          </div>
        )}
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              {isUserAdmin && <th>id</th>}
              {isUserAdmin && <th>TaskId</th>}
              <th>Nom</th>
              <th>Pays</th>
              <th>Type</th>
              <th>Nombre de produits</th>
              {isUserAdmin && <th>Status</th>}
              {isUserAdmin && <th>Octoparse Status</th>}
              <th>Derniere mise à jour</th>
              {isUserAdmin && <th>Interval de cleaning</th>}
              {isUserAdmin && <th>Modifier</th>}
              {isUserAdmin && <th>Programmer</th>}
            </tr>
          </thead>
          <tbody>
            {suppliers.map((supplier) => (
              <SupplierRow
                key={supplier}
                supplierId={supplier}
                processingData={JSON.parse(processingData?.[supplier] || '{}')}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export { Supplier }
