/* eslint-disable no-underscore-dangle */
import { createSelector, createSlice } from '@reduxjs/toolkit'

export const reducerName = 'Login'
export const initialState = {
  user: '',
  error: '', // for error msg
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload
      state.loading = false
      state.errorMsg = false
    },
    apiError(state, action) {
      state.error = action.payload
      state.loading = true
      state.isUserLogout = false
      state.errorMsg = true
    },
    resetLoginFlag(state) {
      // state.error = null;
      state.error = ''
      state.loading = false
      state.errorMsg = false
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
      state.loading = false
      state.errorMsg = false
      state.user = null as any
    },
  },
})

export const { loginSuccess, apiError, resetLoginFlag, logoutUserSuccess } =
  loginSlice.actions

export default loginSlice.reducer
// const selectors = createSelector()
export const state = (s) => s[reducerName]
export const user: any = createSelector(
  [state],
  (substate) => substate.user
) as any
export const userId = createSelector([user], (substate) => substate._id)
export const isProfileCompleted = createSelector([user], (substate) =>
  Boolean(substate?.isProfileCompleted)
)

export const isAdmin = createSelector([user], (substate) =>
  Boolean(substate.roles?.includes('ADMIN'))
)
export const selectors = {
  state,
  user,
  userId,
  isProfileCompleted,
  isAdmin,
}
