import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import * as data from 'data'

export const reducerName = 'invoices'
const invoiceSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = invoiceSlice
export { actions, reducer, selectors }

export const fetch = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    const res = await data.subscription.get('invoices')
    dispatch(
      actions.fetchSuccess({
        data: res.data.data,
        loadMore: false,
      })
    )
  } catch (error) {
    dispatch(actions.fetchError())
  }
}
