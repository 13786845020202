import React, { useState } from 'react'
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap'
import { TSupplier } from 'data/SupplierEntity'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import { COUNTRY_FLAGS, COUNTRY_NAMES, getTimeDifference } from 'common/utils'
import moment from 'moment'
import * as R from 'ramda'
import toastr from 'toastr'

const TypeNames = {
  pro: 'Site professionnel',
  supplier: 'Site marchand',
}
const CLASSNAMESTATUS = {
  ACTIVE: 'btn-rounded btn-outline-success',
  PROCESSING: 'btn-rounded btn-outline-info',
  INACTIVE: 'btn-rounded btn-outline-danger',
  WAITING: 'btn-rounded btn-outline-warning',
  FINISHED: 'btn-rounded btn-outline-secondary',
  ERROR: 'btn-rounded btn-outline-danger',
}

const dateKeys = ['lastOctoParseFetch', 'lastUpdateDate']
const ProcessingKeys = {
  lastOctoParseFetch: 'Dernière mise à jour Octoparse',
  lastUpdateDate: 'Dernière mise à jour',
  numberOfAddedProducts: 'Nombre de produits ajoutés',
  numberOfUpdatedProducts: 'Nombre de produits mis à jour',
  numberOfFailedEanProducts: 'Nombre de produits avec EAN/ASIN invalide',
  status: 'Statut',
  progress: 'Progression',
  total: 'Nombre total de produits chez Octoparse',
  offset: 'Produits scrapés',
  restTotal: 'Produits restants',
  processId: 'ID du processus',
}
const SupplierRow = ({
  supplierId,
  processingData = {},
}: {
  supplierId: string
  processingData?: any
}) => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [modal, setModal] = useState(false)

  const isUserAdmin = useSelector(Store.login.selectors.isAdmin)
  const supplier = useSelector(
    (s: any) =>
      Store.suppliers.selectors.getOneById(s, { id: supplierId }) as any
  )
  const [formSupplier, setSupplier] = useState<Partial<TSupplier> | null>({
    name: supplier.name,
    country: supplier.country,
    type: supplier.type,
    taskId: supplier.taskId,
    status: supplier.status,
  })

  const onChange = (e: any) => {
    setSupplier({
      ...(formSupplier || {}),
      [e.target.name]: e.target.value,
    } as any)
  }

  const onClose = () => {
    setIsEditing(false)
    setModal(false)
  }
  const toggle = () => setModal(!modal)

  const activeSupplier = async () => {
    if (
      supplier.octoParseStatus === 'READY_TO_PROCESS' &&
      supplier.status !== 'ACTIVE'
    ) {
      await dispatch(Store.suppliers.activeSupplier(supplierId) as any)
      return
    }

    toastr.error('Supplier is not ready to process')
    // await dispatch(Store.suppliers.activeSupplier(supplierId) as any)
  }
  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }

  const onEditSupplier = async () => {
    setIsEditing(false)
    if (
      formSupplier &&
      Object.keys(formSupplier).every(
        (key) => formSupplier?.[key] === supplier[key]
      )
    )
      return
    let cleanFormSupplier = Object.keys(formSupplier as any).reduce(
      (acc, key) => {
        if (formSupplier?.[key] !== supplier[key])
          return { ...acc, [key]: formSupplier?.[key] }
        return acc
      },
      {}
    )

    if (
      !!formSupplier?.taskId &&
      !!formSupplier?.country &&
      !!formSupplier?.type &&
      supplier.status === 'WAITING'
    ) {
      cleanFormSupplier = {
        ...cleanFormSupplier,
        status: 'ACTIVE',
      }
    }
    dispatch(
      Store.suppliers.editSupplier(supplier._id, cleanFormSupplier) as any
    )
  }

  return (
    <>
      {isUserAdmin && (
        <Modal isOpen={modal} toggle={onClose} backdrop>
          <ModalHeader toggle={onClose}>{supplier.name}</ModalHeader>
          <ModalBody>
            <Row>
              <Table>
                {Object.keys(ProcessingKeys).map((key) =>
                  processingData?.[key] ? (
                    <tr key={key}>
                      <td>
                        <strong>{ProcessingKeys[key]}</strong>
                      </td>
                      <td>
                        {dateKeys.includes(key)
                          ? moment(processingData?.[key]).format(
                              'YYYY-MM-DD HH:mm'
                            )
                          : processingData?.[key]}
                      </td>
                    </tr>
                  ) : null
                )}
              </Table>
            </Row>
            <Row>
              {processingData?.errors?.length > 0
                ? processingData?.errors.map((error) => (
                    <Col key={error} xs={12}>
                      <br />
                      {error}
                    </Col>
                  ))
                : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <tr key={supplier._id}>
        {isUserAdmin && <td>{supplier._id}</td>}
        {isUserAdmin && (
          <td>
            {isEditing ? (
              <Input
                name="taskId"
                onChange={onChange}
                value={formSupplier?.taskId}
                placeholder={supplier.taskId}
              />
            ) : (
              <button
                className="btn btn-link"
                type="button"
                onClick={() => {
                  copyTextToClipboard(supplier.taskId)
                }}
              >
                <i className="bx bx-book font-size-16 align-middle me-2" />
                {supplier.taskId}
              </button>
            )}
          </td>
        )}
        <td>
          {isEditing ? (
            <Input
              name="name"
              onChange={onChange}
              value={formSupplier?.name}
              placeholder={supplier.name}
            />
          ) : (
            supplier.name
          )}
        </td>
        <td>
          {isEditing ? (
            <Input
              name="country"
              type="select"
              onChange={onChange}
              value={formSupplier?.country}
              placeholder={supplier.country}
            >
              {Object.keys(COUNTRY_NAMES).map((key) => (
                <option key={key} value={key}>
                  {COUNTRY_NAMES[key]}
                </option>
              ))}
            </Input>
          ) : (
            COUNTRY_FLAGS[
              supplier.country === 'UNKNOWN' ? '' : supplier.country
            ]
          )}
        </td>
        <td>
          {isEditing ? (
            <Input
              name="type"
              type="select"
              onChange={onChange}
              value={formSupplier?.type || supplier.type}
              placeholder={supplier.type}
            >
              {Object.keys(TypeNames).map((key) => (
                <option key={key} value={key}>
                  {TypeNames[key]}
                </option>
              ))}
            </Input>
          ) : (
            TypeNames[supplier.type]
          )}
        </td>
        <td>{supplier?.numberOfProducts}</td>
        {isUserAdmin && (
          <td>
            {isEditing ? (
              <Input
                name="status"
                type="select"
                onChange={onChange}
                value={formSupplier?.status || supplier.status}
                placeholder={supplier.status}
              >
                {Object.keys(CLASSNAMESTATUS).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </Input>
            ) : (
              <button
                type="button"
                disabled={R.isEmpty(processingData)}
                onClick={toggle}
                className={`btn ${
                  CLASSNAMESTATUS[processingData?.status || supplier.status] ||
                  ''
                }`}
              >
                {supplier.status === 'PROCESSING' ||
                  (processingData?.status === 'PROCESSING' && (
                    <i className="bx bx-loader bx-spin font-size-16 'align-middle me-2" />
                  ))}
                {processingData?.status === 'FINISHED'
                  ? supplier.status
                  : processingData?.status || supplier.status}{' '}
                {R.isEmpty(processingData) ? '' : '*'}
              </button>
            )}
          </td>
        )}
        <td>
          {isUserAdmin && <div>{supplier.octoParseStatus}</div>}
          {isUserAdmin && (
            <div>{getTimeDifference(supplier.lastOctoParseStatusUpdate)}</div>
          )}
        </td>
        <td>
          {moment(new Date(supplier.lastUpdateDate)).format('DD-MM-YYYY H:m:s')}
        </td>
        {isUserAdmin && (
          <td>
            {isEditing ? (
              <Input
                type="number"
                name="outDateInterval"
                onChange={onChange}
                defaultValue={supplier.outDateInterval}
              />
            ) : (
              `${supplier.outDateInterval}jours`
            )}
          </td>
        )}
        <td>
          {isUserAdmin && (
            <td>
              <button
                type="button"
                disabled={
                  supplier.octoParseStatus !== 'READY_TO_PROCESS' ||
                  supplier.status === 'ACTIVE'
                }
                className="btn btn-warning btn-rounded"
                onClick={activeSupplier}
              >
                SEND TO PROCESS
              </button>
            </td>
          )}
        </td>
        {isUserAdmin && (
          <td>
            {isEditing ? (
              <Button
                color="primary btn-rounded btn-success"
                onClick={onEditSupplier}
              >
                Valider
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                Modifier
              </Button>
            )}
          </td>
        )}
        {isUserAdmin && (
          <td>
            <button
              type="button"
              className={`btn ${supplier.isActive ? 'btn-danger' : 'btn-info'}`}
              onClick={async () => {
                setSupplier({
                  ...formSupplier,
                  isActive: !supplier.isActive,
                })
                await onEditSupplier()
              }}
            >
              {supplier.isActive ? 'Désactiver' : 'Activer'}
            </button>
          </td>
        )}
        {/* <td>{supplier.taskId}</td> */}
      </tr>
    </>
  )
}

export { SupplierRow }
