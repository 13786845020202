import toastr from 'toastr'
import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import * as data from 'data'

export const reducerName = 'subscriptions'
const subscriptionSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = subscriptionSlice
export { actions, reducer, selectors }

export const cancelSubscription =
  (subscriptionId: string) => async (dispatch: any) => {
    try {
      const subscription = await data.subscription.delete(subscriptionId, {
        subscriptionId,
      })
      console.log('subscription', subscription)

      dispatch(actions.editSuccess(subscription.data))
      toastr.success('Plan cancelled successfully!', 'Success')
    } catch (err) {
      toastr.error('Error cancelling plan!', 'Error')
      console.log('err', err)
    }
  }

export const createSubscription =
  ({ priceId, coupon }: { priceId: string; coupon: string }) =>
  async (dispatch: any, getState) => {
    const customerId = getState().Login.user.stripeCustomerId
    try {
      return await data.subscription.post('', {
        priceId,
        customerId,
        coupon,
      })
    } catch (error) {
      toastr.error('Error creating subscription!', 'Error')
      return null
    }
  }

export const updateSubscription =
  (subscriptionId: string, priceId: string) => async () => {
    try {
      await data.subscription.put('', {
        subscriptionId,
        priceId,
      })

      toastr.options = {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        closeButton: true,
        progressBar: true,
        newestOnTop: true,
        rtl: false,
        preventDuplicates: false,
      }
      toastr.success('Plan updated successfully!', 'Success')
    } catch (err: any) {
      toastr.error('Error updating plan!', 'Error')
      console.log('err', err)
    }
  }

export const getCoupon = (coupon: string) => async () => {
  try {
    return await data.subscription.get(`coupon/${coupon}`)
  } catch (err) {
    toastr.error('Error getting coupon!', 'Error')
    console.log('err', err)
  }
}
// export const fetchPrices = () => async (dispatch: any) => {
//   dispatch(actions.fetchRequest())
//   try {
//     const res = await data.subscription.get('prices')
//     dispatch(
//       actions.fetchSuccess({
//         data: res.data.data,
//         loadMore: false,
//       })
//     )
//   } catch (error) {
//     dispatch(actions.fetchError())
//   }
// }
