import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit'

export type GenericState<
  T extends { _id: string },
  S = NonNullable<unknown>,
> = {
  isLoading: boolean
  isLoadingMore: boolean
  isSubmitting: boolean
  hasMore: boolean
  allIds: string[]
  ids: string[]
  indexes: Record<string, T>
  lastUpdate: Date
  error?: {
    message: string
  }
} & S

const getInitialState = <T extends { _id: string }, S>() =>
  ({
    isLoading: false,
    isLoadingMore: false,
    isSubmitting: false,
    hasMore: false,
    allIds: [],
    ids: [],
    indexes: {} as Record<string, T>,
    lastUpdate: new Date().toString(),
    error: undefined,
  }) as GenericState<T, S>

export const mergeData = <T extends { _id: string; id?: string }, S>(
  state: GenericStateType<T, S>,
  data: T[],
  groupKeyValues?: { [key in keyof S]: keyof T },
  loadMore = false
): GenericStateType<T, S> => {
  // Handling groupKeyValues
  if (groupKeyValues) {
    for (const [stateKey, indexValue] of Object.entries(groupKeyValues)) {
      if (!loadMore) {
        state[stateKey] = {}
      }
      state[stateKey] = data.reduce((acc, current) => {
        const value = current[indexValue as any]
        if (!value) return acc
        const valArray = Array.isArray(value) ? value : [value]
        valArray.forEach((val) => {
          acc[val] = acc[val] ? [...acc[val], current._id] : [current._id]
        })
        return acc
      }, state[stateKey] || {})
    }
  }

  // Handling indexes, allIds, and ids
  const newIndexes = data.reduce((acc, item) => {
    acc[item._id || (item.id as any)] = item
    return acc
  }, {})

  const newAllIds = data.map((item) => item._id || (item.id as any))
  const newIds = newAllIds // Assuming 'ids' should be handled the same as 'allIds'

  if (loadMore) {
    state.indexes = { ...state.indexes, ...newIndexes }
    state.allIds = [...new Set([...state.allIds, ...newAllIds])].sort()
    state.ids = [...new Set([...state.ids, ...newIds])].sort()
  } else {
    state.indexes = newIndexes
    state.allIds = newAllIds.sort()
    state.ids = newIds.sort()
  }

  return state
}

export const remove = <T extends { id: string }, S>(
  state: GenericStateType<T, S>,
  itemIds: string[],
  groupKeyValues?: { [key in keyof S]: keyof T }
): GenericStateType<T, S> => {
  state.allIds = state.allIds.filter((id) => !itemIds.includes(id))
  state.ids = state.ids.filter((id) => !itemIds.includes(id))

  itemIds.forEach((itemId) => {
    delete state.indexes[itemId]
  })

  if (groupKeyValues) {
    for (const stateKey of Object.keys(groupKeyValues)) {
      for (const vec of Object.keys(state[stateKey])) {
        state[stateKey][vec] = state[stateKey][vec].filter(
          (id) => !itemIds.includes(id)
        )
      }
    }
  }
  return state
}

export const createGenericSlice = <T extends { _id: string; id?: string }, S>({
  name = 'products',
  extra,
  groupByKeys,
}: {
  name: string
  extra?: S
  groupByKeys?: { [key in keyof S]: keyof T }
}) => {
  type TState = GenericStateType<T>
  const initialState = { ...getInitialState<T, S>(), ...extra }
  return createSlice({
    name,
    initialState,
    reducers: {
      editRequest: (state) => {
        state.isSubmitting = true
      },
      deleteRequest: (state) => {
        state.isSubmitting = true
      },
      fetchRequest: (state) => {
        state.isLoading = true
      },
      editError: (state) => {
        state.isSubmitting = false
      },
      deleteError: (state) => {
        state.isSubmitting = false
      },
      fetchError: (state) => {
        state.isLoading = false
      },

      editSuccess: (state, action: PayloadAction<T>) => {
        state.isSubmitting = false
        return mergeData(
          state as GenericState<T, S>,
          [action.payload],
          groupByKeys,
          true
        )
      },

      deleteSuccess: (state, action: PayloadAction<string>) => {
        state.isSubmitting = false
        delete state.indexes[action.payload]
      },

      fetchSuccess: (
        state,
        action: PayloadAction<{
          data: T[]
          loadMore?: boolean
        }>
      ) => {
        const mergedState = mergeData(
          state as GenericState<T, S>,
          action.payload.data,
          groupByKeys,
          action.payload.loadMore
        )
        mergedState.isLoading = false
      },
      set: (
        state,
        action: PayloadAction<{ value: ValueOf<TState>; key: keyof TState }>
      ) => {
        state[action.payload.key] = action.payload.value
      },
      add: (state, action: PayloadAction<T>) => {
        state.indexes[action.payload._id || (action.payload?.id as any)] =
          action.payload as Draft<T>
      },
      reset: (state) => {
        state = { ...getInitialState() }
        return state
      },
    },
  })
}
