import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isUserLogout: true,
  redirect: '',
}

const registerSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    registerUserSuccessful(state, action) {
      state.user = action.payload
      state.loading = false
      state.success = true
      state.registrationError = null
      state.redirect = '/login'
    },
    registerUserFailed(state, action) {
      state.user = null
      state.loading = false
      state.registrationError = action.payload
      state.error = true
      state.redirect = ''
    },
    resetRegisterFlagChange(state) {
      state.success = false
      state.error = false
    },
    apiErrorChange(state, action) {
      state.error = action.payload
      state.loading = false
      state.isUserLogout = false
      state.redirect = ''
      state.registrationError = null
    },
  },
})

export const {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
} = registerSlice.actions

export default registerSlice.reducer
