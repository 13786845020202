import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import { TProduct } from 'data/ProductEntity'
import * as data from 'data'

export const reducerName = 'products'
const productsSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = productsSlice
export { actions, reducer, selectors }

export const fetch = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    // const res = await data.product.fetch()
    // dispatch(
    //   actions.fetchSuccess({
    //     data: res,
    //     loadMore: false,
    //   })
    // )
  } catch (error) {
    dispatch(actions.fetchError())
  }
}
