import moment from 'moment'
import React from 'react'
import { useLocation } from 'react-router-dom'

const ASIN_REGEX = /^[A-Z0-9]{10}$/
const EAN_REGEX = /^\d{13}$/
const UPC_REXEX = /^\d{12}$/

export { ASIN_REGEX, EAN_REGEX, UPC_REXEX }
export const DAYS_OF_WEEK = {
  0: 'Dimanche',
  1: 'Lundi',
  2: 'Mardi',
  3: 'Mercredi',
  4: 'Jeudi',
  5: 'Vendredi',
  6: 'Samedi',
}
export const COUNTRY_FLAGS = {
  DE: '🇩🇪',
  AT: '🇦🇹',
  BE: '🇧🇪',
  BG: '🇧🇬',
  CY: '🇨🇾',
  HR: '🇭🇷',
  DK: '🇩🇰',
  ES: '🇪🇸',
  EE: '🇪🇪',
  FI: '🇫🇮',
  FR: '🇫🇷',
  GR: '🇬🇷',
  HU: '🇭🇺',
  IE: '🇮🇪',
  IT: '🇮🇹',
  LV: '🇱🇻',
  LT: '🇱🇹',
  LU: '🇱🇺',
  MT: '🇲🇹',
  NL: '🇳🇱',
  PL: '🇵🇱',
  PT: '🇵🇹',
  CZ: '🇨🇿',
  RO: '🇷🇴',
  UK: '🇬🇧',
  SK: '🇸🇰',
  SI: '🇸🇮',
  SE: '🇸🇪',
  CH: '🇨🇭',
}

export const COUNTRY_NAMES = {
  DE: 'Allemagne',
  AT: 'Autriche',
  BE: 'Belgique',
  BG: 'Bulgarie',
  CY: 'Chypre',
  HR: 'Croatie',
  DK: 'Danemark',
  ES: 'Espagne',
  EE: 'Estonie',
  FI: 'Finlande',
  FR: 'France',
  GR: 'Grèce',
  HU: 'Hongrie',
  IE: 'Irlande',
  IT: 'Italie',
  LV: 'Lettonie',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MT: 'Malte',
  NL: 'Pays-Bas',
  PL: 'Pologne',
  PT: 'Portugal',
  CZ: 'République Tchèque',
  RO: 'Roumanie',
  UK: 'Royaume-Uni',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  SE: 'Suède',
  CH: 'Suisse',
}

export const TVA_RATES = {
  DE: 19,
  AT: 20,
  BE: 21,
  BG: 20,
  CY: 19,
  HR: 25,
  DK: 25,
  ES: 21,
  EE: 20,
  FI: 24,
  FR: 20,
  GR: 24,
  HU: 27,
  IE: 23,
  IT: 22,
  LV: 21,
  LT: 21,
  LU: 16,
  MT: 18,
  NL: 21,
  PL: 23,
  PT: 23,
  CZ: 21,
  RO: 19,
  UK: 20,
  SK: 20,
  SI: 22,
  SE: 25,
  CH: 7.7,
}
export const getNextOccurrence = (
  from: Date,
  dayOfWeek: string,
  hours: number,
  minutes: number
): typeof moment => {
  const refDate = moment(from)
  const nextOccurrence: typeof moment = moment()
    .day(dayOfWeek)
    .hours(hours)
    .minutes(minutes)
    .seconds(0)

  // If it's already past that time this week, get the next week's occurrence
  if (nextOccurrence.isBefore(refDate)) {
    nextOccurrence.add(1, 'weeks')
  }

  return nextOccurrence
}

export const HIDDEN_FIELDS = [
  'userId',
  'roles',
  'id',
  'authId',
  '_id',
  '__v',
  'stripeCustomerId',
  'password',
  'createdAt',
  'plan',
  'planId',
  'subscriptionId',
  'planStartAt',
  'planEndAt',
  'planStatus',
  'resetToken',
  'resetTokenExpires',
  'verificationToken',
  'verificationTokenExpires',
  'isProfileCompleted',
  'isEmailVerified',
  'hasBasicPlan',
  'hasPremiumPlan',
  'hasFreePlan',
]

export const dateFields = ['planStartAt', 'planEndAt', 'createdAt']
export const selectFields = ['planStatus', 'plan', 'roles']
export const booleanFields = [
  'isProfileCompleted',
  'isEmailVerified',
  'hasBasicPlan',
  'hasPremiumPlan',
  'hasFreePlan',
]
export const numberFields = ['phoneNumber']
export const emailFields = ['email']
export const stringFields = ['firstName', 'lastName']
export const passwordFields = ['password']
export const selectFieldsOptions = {
  planStatus: [
    'active',
    'inactive',
    'canceled',
    'trialing',
    'past_due',
    'unpaid',
  ],
  plan: ['FREE', 'BASIC', 'PREMIUM'],
  roles: ['USER', 'CUSTOMER', 'ADMIN'],
}
export const AllowedFields = {
  email: 'Votre adresse email',
  firstName: 'Votre prénom',
  lastName: 'Votre nom',
  phoneNumber: 'Votre numéro de téléphone',
}

export const adminFlields = {
  email: 'Adresse email',
  firstName: 'Prénom',
  lastName: 'Nom',
  phoneNumber: 'Numéro de téléphone',
  stripeCustomerId: 'ID Stripe',
  planStartAt: "Début de l'abonnement",
  planEndAt: "Fin de l'abonnement",
  planStatus: "Statut de l'abonnement",
  plan: 'Abonnement',
}
export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function getTimeDifference(date) {
  const now = moment()
  const givenDate = moment(date)
  const diffInSeconds = now.diff(givenDate, 'seconds')

  if (diffInSeconds < 60) {
    return `il y a ${diffInSeconds}s`
  }

  const diffInMinutes = now.diff(givenDate, 'minutes')
  if (diffInMinutes < 60) {
    return `il y a ${diffInMinutes}mn`
  }

  const diffInHours = now.diff(givenDate, 'hours')
  if (diffInHours < 24) {
    return `il y a ${diffInHours}h`
  }

  const diffInDays = now.diff(givenDate, 'days')
  return `il y a ${diffInDays} jour${diffInDays > 1 ? 's' : ''}`
}
