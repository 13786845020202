import { APIClient } from '../helpers/api_helper'

export type TUser = {
  id: string
  email: string
  firstName: string
  lastName: string
  companyName: string
  companyID: string
  vatId: string
  phoneNumber: string
  address: string
  zipcode: string
  country: string
  authId: string
  roles: string[]
}
class UserEntity extends APIClient<TUser> {}
export { UserEntity }
