import { userForgetPasswordSuccess, userForgetPasswordError } from './reducer'

// Include Both Helper File with needed methods
// import { firebaseAuth } from '../../../helpers/firebase'

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from '../../../helpers/fakebackend_helper'

export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
    // let response: any
    // if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
    //   // response = await firebaseAuth.sendPasswordResetEmail(user.email, {
    //   //   url: 'http://localhost:3000/test.email',
    //   // })
    // } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
    console.log('user', user)

    const response = postJwtForgetPwd(user.email)
    // } else {
    //   response = postFakeForgetPwd(user.email)
    // }

    const data = await response
    console.log('data', response)

    if (data) {
      dispatch(
        userForgetPasswordSuccess(
          'Un email de réinitialisation de mot de passe a été envoyé à votre adresse email.'
        )
      )
    }
  } catch (forgetError) {
    console.log('forgetError', forgetError)

    dispatch(userForgetPasswordError(forgetError))
  }
}
