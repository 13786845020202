import React, { useEffect, useState } from 'react'

// Redux
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'

// import images
import { loginuser, resetLoginMsgFlag } from 'slices/auth/login/thunk'
import withRouter from 'Components/Common/withRouter'
import { createSelector } from 'reselect'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'common/utils'
import profile from '../../assets/images/profile-img.png'
import logo from '../../assets/images/logoR.png'
import lightlogo from '../../assets/images/logolg.png'
import { loginErrorMessage } from '../../common/errorMessages'

const onMaintenance = process.env.REACT_APP_ON_MAINTENANCE === 'true'
// import thunk

const Login = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const emailValidation = query.get('emailValidation')
  const [show, setShow] = useState(false)
  const [showConfirmationEmailBtn, setShowConfirmationEmailBtn] =
    useState(false)
  const dispatch: any = useDispatch()

  // meta title
  document.title = 'Login | ResellCorner'

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  )

  const { error, state } = useSelector(selectProperties)

  // Form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, navigate))
    },
  })

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag())
      }, 3000)
    }

    if (error == loginErrorMessage.EMAIL_NOT_VERIFIED) {
      setShowConfirmationEmailBtn(true)
    }
  }, [dispatch, error])

  const getNewConfirmationEmail = () => {
    // TODO: implement logic to send email here
  }

  return (
    <div className="account-pages my-5 pt-sm-5">
      {localStorage.getItem('disclaimer') && (
        <Alert
          style={{
            zIndex: 1000000,
            alignSelf: 'center',
            textAlign: 'center',
          }}
          color="danger"
        >
          <h1>{localStorage.getItem('disclaimer')}</h1>
        </Alert>
      )}
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-primary bg-soft">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">
                        Bienvenue sur Resellcorner
                      </h5>
                      <p>Connexion.</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profile} alt="" className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="auth-logo">
                  <Link to="/" className="auth-logo-light">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={lightlogo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                  <Link to="/" className="auth-logo-dark">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="p-2">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="mb-3">
                      {error ? (
                        <Alert key="loginError" color="danger">
                          {error}
                        </Alert>
                      ) : null}
                      {emailValidation ? (
                        <Alert key="loginError" color="success">
                          Votre compte est validé avec succès. Veuillez vous
                          connecter pour continuer.
                        </Alert>
                      ) : null}
                      {showConfirmationEmailBtn ? (
                        <button
                          className="btn btn-outline-secondary btn-block mb-4"
                          type="button"
                          onClick={() => {}}
                        >
                          Recevoir un nouvel email de confirmation
                        </button>
                      ) : null}
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={
                          !!(
                            validation.touched.email && validation.errors.email
                          )
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          value={validation.values.password || ''}
                          type={show ? 'text' : 'password'}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(
                              validation.touched.password &&
                              validation.errors.password
                            )
                          }
                        />
                        <button
                          onClick={() => setShow(!show)}
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                        >
                          <i className="mdi mdi-eye-outline" />
                        </button>
                      </div>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Se souvenir de moi
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block "
                        type="submit"
                      >
                        Connexion
                      </button>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <h5 className="font-size-14 mb-3">Sign in with</h5>

                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-primary text-white border-primary"
                            onClick={(e: any) => {
                              e.preventDefault()
                              socialResponse('facebook')
                            }}
                          >
                            <i className="mdi mdi-facebook" />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-info text-white border-info"
                          >
                            <i className="mdi mdi-twitter" />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link
                            to="#"
                            className="social-list-item bg-danger text-white border-danger"
                            onClick={(e: any) => {
                              e.preventDefault()
                              socialResponse('google')
                            }}
                          >
                            <i className="mdi mdi-google" />
                          </Link>
                        </li>
                      </ul>
                    </div> */}

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" /> Mot de passe oublié
                        ?
                      </Link>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Vous n'avez pas encore de compte ?{' '}
                <Link to="/register" className="fw-medium text-primary">
                  {' '}
                  Inscription ICI{' '}
                </Link>{' '}
              </p>
              <p>© {new Date().getFullYear()}ResellCorner.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withRouter(Login)
