import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import * as data from 'data'
import * as R from 'ramda'

export const reducerName = 'prices'
const priceSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = priceSlice
export { actions, reducer, selectors }

export const fetchPrices = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    const res = await data.subscription.get('prices')
    const formattedPrices: any = res.data.data.map((price) => ({
      id: price.id,
      title: price.product.name || 'FREE',
      description: price.product.description,
      price: price.unit_amount / 100,
      duration: 'Per month',
      link: '',
      features: price.product.features,
    }))
    const allPrices: any = R.sortBy(R.prop('price'), [...formattedPrices])
    dispatch(
      actions.fetchSuccess({
        data: allPrices,
        loadMore: false,
      })
    )
  } catch (error) {
    dispatch(actions.fetchError())
  }
}
