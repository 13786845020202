import { ProductEntity } from './ProductEntity'
import { SubscriptionEntity } from './SubscriptionEntity'
import { SupplierEntity } from './SupplierEntity'
import { UserEntity } from './UserEntity'
import { UserLanding } from './UserLanding'

const user = new UserEntity('/users')
const product = new ProductEntity('/products')
const userLanding = new UserLanding('/user-landing')
const supplier = new SupplierEntity('/suppliers')
const subscription = new SubscriptionEntity('/subscriptions')

export { user, product, supplier, subscription, userLanding }
