import { APIClient } from '../helpers/api_helper'

export type TSupplier = {
  _id: string
  name: string
  slug: string
  url: string
  products: string[]
  lastUpdateDate: Date
  nextUpdateDates: Date[]
  country: string
  taskId?: string
  type: string
  status: string
  isActive: boolean
}
class SupplierEntity extends APIClient<TSupplier> {}
export { SupplierEntity }
