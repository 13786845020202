import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import * as data from 'data'
import * as R from 'ramda'
import toastr from 'toastr'
import { logoutUser } from './thunk'

export const reducerName = 'suppliers'
const supplierSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = supplierSlice
export { actions, reducer, selectors }

export const fetchSuppliers =
  (force = false) =>
  async (dispatch: any) => {
    dispatch(actions.fetchRequest())
    try {
      const path = force ? '?force=true' : ''
      const res = await data.supplier.get(path)

      const { data: results } = res

      dispatch(
        actions.fetchSuccess({
          data: results,
          loadMore: false,
        })
      )
    } catch (error) {
      if (error === 'Invalid credentials') {
        dispatch(actions.fetchError())
        dispatch(logoutUser())
      }
    }
  }

export const editSupplier =
  (id: string, updatedData: any) => async (dispatch: any) => {
    dispatch(actions.editRequest())
    try {
      const res = await data.supplier.put(`${id}`, updatedData)

      const { data: result } = res
      console.log('result', result)

      // dispatch(actions.editError())
      dispatch(actions.editSuccess(result))
    } catch (error) {
      dispatch(actions.editError())
    }
  }

export const refreshOctoparseData = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    const res = await data.supplier.get('refresh')
    const { data: result } = res
    dispatch(actions.fetchSuccess({ data: result, loadMore: false }))
    return true
  } catch (error) {
    if (error === 'Invalid credentials') {
      dispatch(actions.fetchError())
      dispatch(logoutUser())
      return false
    }
    dispatch(actions.fetchError())
    return false
  }
}

export const setProgram =
  (program: { id: string; days: number[]; hours: number; minutes: number }) =>
  async (dispatch: any) => {
    dispatch(actions.editRequest())
    try {
      const res = await data.supplier.post('program', program)
      const { data: result } = res
      console.log('result', result)

      dispatch(actions.editSuccess(result))
      toastr.success('Programme mis à jour', 'Succès')
    } catch (error) {
      dispatch(actions.editError())
      console.log('error', error)

      toastr.error('Une erreur est survenue', 'Erreur')
    }
  }
export const activeSupplier = (id: string) => async (dispatch: any) => {
  dispatch(actions.editRequest())
  console.log('id', id)

  try {
    const res = await data.supplier.post(`${id}/activate`, {})
    const { data: result } = res
    console.log('result', result)

    dispatch(actions.editSuccess(result))
    toastr.success('Fournisseur activé', 'Succès')
  } catch (error) {
    dispatch(actions.editError())
    console.log('error', error)

    toastr.error('Une erreur est survenue', 'Erreur')
  }
}
