import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import * as Store from 'slices'
import toastr from 'toastr'
import { redirectRoutes } from './redirectRoutes'

const AuthProtected = (props) => {
  const user: any = useSelector(Store.login.selectors.user)
  const navigate = useNavigate()
  const location = useLocation()
  const isProfileCompleted = useSelector(
    Store.login.selectors.isProfileCompleted
  )

  if (!user) {
    return <Navigate to={redirectRoutes.LOGIN} />
  }

  if (!isProfileCompleted && location.pathname != redirectRoutes.UPDATE_USER) {
    return <Navigate to={redirectRoutes.UPDATE_USER} />
  }

  if (user?.plan === 'FREE' && location.pathname === redirectRoutes.SUPPLIER) {
    navigate(redirectRoutes.PLAN)
    toastr.error(
      "Votre abonnement n'inclus pas cette fonctionnalité",
      'Abonnement'
    )
  }

  return <>{props.children}</>
}

export default AuthProtected
