import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container, FormGroup, Input, Label } from 'reactstrap'
import * as Store from 'slices'
import toastr from 'toastr'

const ContactSupport = () => {
  const dispatch = useDispatch()
  const [isSending, setIsSending] = React.useState(false)
  const [formContact, setFormContact] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  })

  const onChange = (e) => {
    setFormContact({ ...formContact, [e.target.name]: e.target.value })
  }

  const onSend = async () => {
    setIsSending(true)
    if (
      !formContact.firstName ||
      !formContact.lastName ||
      !formContact.email ||
      !formContact.message
    ) {
      toastr.error('Veuillez remplir tous les champs')
      return
    }
    const ok = await dispatch(Store.login.contactSupport(formContact) as any)
    if (!ok) {
      toastr.error('Une erreur est survenue')
      return
    }
    setFormContact({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    })
    toastr.success('Votre message a été envoyé avec succès')
    console.log('formContact', formContact)
    setIsSending(false)
  }
  return (
    <div className="page-content">
      <Container fluid>
        <h1>CONTACTER LE SUPPORT</h1>
        <FormGroup>
          <Label>PRENOM</Label>
          <Input
            type="text"
            name="firstName"
            onChange={onChange}
            //   name={userKey}
            //   id={userKey}
            //   value={formik.values[userKey] || ''}
            //   onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            //   invalid={
            //     formik.touched[userKey] && !!formik.errors[userKey]
            //   }
          />
        </FormGroup>
        <FormGroup>
          <Label>NOM</Label>
          <Input
            type="text"
            name="lastName"
            //   name={userKey}
            //   id={userKey}
            //   value={formik.values[userKey] || ''}
            onChange={onChange}
            //   onBlur={formik.handleBlur}
            //   invalid={
            //     formik.touched[userKey] && !!formik.errors[userKey]
            //   }
          />
        </FormGroup>
        <FormGroup>
          <Label>EMAIL</Label>
          <Input
            type="text"
            name="email"
            //   name={userKey}
            //   id={userKey}
            //   value={formik.values[userKey] || ''}
            onChange={onChange}
            //   onBlur={formik.handleBlur}
            //   invalid={
            //     formik.touched[userKey] && !!formik.errors[userKey]
            //   }
          />
        </FormGroup>
        <FormGroup>
          <Label>MESSAGE</Label>
          <Input
            type="textarea"
            onChange={onChange}
            name="message"
            //   id={userKey}
            //   value={formik.values[userKey] || ''}
            //   onChange={formik.handleChange}
            //   onBlur={formik.handleBlur}
            //   invalid={
            //     formik.touched[userKey] && !!formik.errors[userKey]
            //   }
          />
        </FormGroup>
        <Button disabled={isSending} onClick={onSend} color="primary">
          ENVOYER
        </Button>
      </Container>
    </div>
  )
}

export { ContactSupport }
