import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Table } from 'reactstrap'
import * as Store from 'slices'

const Invoices = () => {
  const dispatch = useDispatch()
  const invoices = useSelector(Store.invoices.selectors.getAllCollection)

  useEffect(() => {
    document.title = 'Invoices'
    dispatch(Store.invoices.fetch() as any)
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <h1>Vos factures</h1>
        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Montant</th>
              <th>Créer le</th>
              <th>Télécharger</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice: any) => (
              <tr key={invoice.id}>
                <td>{invoice.lines.data[0].description}</td>
                <td>{invoice.amount_due / 100} €</td>
                <td>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
                <td>
                  <a
                    href={invoice.invoice_pdf}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Télécharger
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  )
}

export { Invoices }
