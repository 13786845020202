import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import Select from 'react-select'
import * as R from 'ramda'
import toastr from 'toastr'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as Store from 'slices'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import * as data from 'data'
import { user as userSelector } from '../../slices/auth/login/reducer'
import { redirectRoutes } from '../../Routes/redirectRoutes'

const onMaintenance = process.env.REACT_APP_ON_MAINTENANCE === 'true'
const eanAsinRegex = /^(?:(?:(?:[A-Za-z0-9]{10})|(\d{13}))(?:, ?)?)+$/
const Marketplaces = [
  {
    value: 'FR',
    label: 'https://www.amazon.fr',
  },
  {
    value: 'UK',
    label: 'https://www.amazon.co.uk',
  },
  {
    value: 'DE',
    label: 'https://www.amazon.de',
  },
  {
    value: 'IT',
    label: 'https://www.amazon.it',
  },
  {
    value: 'ES',
    label: 'https://www.amazon.es',
  },
]
const Filters = ({ onSubmit, onCSVParse }) => {
  // const [suppliers, setSuppliers] = useState([])
  const suppliers = useSelector(Store.suppliers.selectors.getAllCollection)
  const supplerIndexByType = R.groupBy(R.prop('type') as any, suppliers)
  const supplierIndexes = useSelector(Store.suppliers.selectors.getIndexes)
  const user: any = useSelector(userSelector)
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    // Section 1 fields
    profitUnitMin: null,
    profitUnitMax: null,
    profitMonthMin: null,
    profitMonthMax: null,
    amazonNotSeller: false,
    isGrouped: false,

    // Section 2 fields
    marketplace: '',
    category: '',
    eanAsinList: '',
    keywordList: '',
    includedEanAsinList: [],
    excludedEanAsinList: [],
    includedKeywordList: [],
    excludedKeywordList: [],

    // Section 3 fields
    priceAmazonMin: null,
    priceAmazonMax: null,
    roiUnitMin: null,
    roiUnitMax: null,
    salesPerMonthMin: null,
    salesPerMonthMax: null,

    // Supplier fields
    supplierType: '',
    supplierEanAsinList: '',
    supplierIncludedEanAsinList: [],
    supplierExcludedEanAsinList: [],
    supplierCountry: '',
    suppliers: [],
    supplierPriceMin: null,
    supplierPriceMax: null,
  })

  const validationSchema = Yup.object().shape({
    // Section 1 fields
    profitUnitMin: Yup.number().min(1).max(1000).nullable(),
    profitUnitMax: Yup.number().min(1).max(1000).nullable(),
    profitMonthMin: Yup.number().min(1).max(10000).nullable(),
    profitMonthMax: Yup.number().min(1).max(10000).nullable(),
    isGrouped: Yup.boolean(),

    // Section 2 fields
    marketplace: Yup.string(),
    category: Yup.string(),
    eanAsinList: Yup.string().matches(eanAsinRegex, 'Invalid EAN/ASIN format'),
    keywordList: Yup.string(),
    includedEanAsinList: Yup.array()
      .of(Yup.string().matches(eanAsinRegex, 'Invalid EAN/ASIN format'))
      .max(5),
    excludedEanAsinList: Yup.array()
      .of(Yup.string().matches(eanAsinRegex, 'Invalid EAN/ASIN format'))
      .max(5),
    includedKeywordList: Yup.array().of(Yup.string()).max(5),
    excludedKeywordList: Yup.array().of(Yup.string()).max(5),

    // Section 3 fields
    priceAmazonMin: Yup.number().min(1).max(1000).nullable(),
    priceAmazonMax: Yup.number().min(1).max(1000).nullable(),
    roiUnitMin: Yup.number().min(1).max(1000).nullable(),
    roiUnitMax: Yup.number().min(1).max(1000).nullable(),
    salesPerMonthMin: Yup.number().min(1).max(10000).nullable(),
    salesPerMonthMax: Yup.number().min(1).max(10000).nullable(),

    // Supplier fields
    supplierType: Yup.string(),
    supplierEanAsinList: Yup.string().matches(
      eanAsinRegex,
      'Invalid EAN/ASIN format'
    ),
    supplierIncludedEanAsinList: Yup.array()
      .of(Yup.string().matches(eanAsinRegex, 'Invalid EAN/ASIN format'))
      .max(5),
    supplierExcludedEanAsinList: Yup.array()
      .of(Yup.string().matches(eanAsinRegex, 'Invalid EAN/ASIN format'))
      .max(5),
    supplierCountry: Yup.string(),
    suppliers: Yup.array(),
    supplierPriceMin: Yup.number().min(1).max(1000).nullable(),
    supplierPriceMax: Yup.number().min(1).max(1000).nullable(),
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers() as any)
  }, [])

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { ...formData, supplierType: 'supplier' },
    validationSchema,
    onSubmit: (values: any) => {
      const cleanedValues = Object.keys(values).reduce((acc, key) => {
        if (
          values[key] === '' ||
          values[key] === null ||
          (Array.isArray(values[key]) && values[key].length === 0)
        ) {
          return acc
        }
        return { ...acc, [key]: values[key] }
      }, {})
      if ((cleanedValues as any)?.suppliers?.length === 0) {
        toastr.error('Veuillez sélectionner au moins un fournisseur')
        return
      }
      onSubmit?.(cleanedValues)
      // dispatch(loginuser(values, navigate))
    },
  })

  const handleSupplierChange = (e) => {
    if (user.roles.includes('ADMIN')) {
      formik.handleChange(e)
      return
    }
    if ((e.target.value === 'pro' && user.hasFreePlan) || user.hasBasicPlan) {
      navigate(redirectRoutes.UPDATE_USER)
      toastr.error(
        'Votre abonnement ne vous permet pas de rechercher sur des sites professionnels',
        'Abonnement'
      )
    } else {
      formik.handleChange(e)
    }
  }

  const handleEanAsinListChange = (
    name: string,
    fieldName: string,
    type?: string
  ) => {
    const includedList = formik.values[fieldName]
    if (includedList.length >= 3) {
      toastr.error(`Le nombre de selection est limité à 3 ${type || 'ASIN'}`)
      formik.setFieldError(`${name}`, 'Only 3 EAN/ASIN/UPC/Keywords allowed')
      return
    }
    if (includedList.includes(formik.values[name])) {
      toastr.warning(`${name} est déjà ajouté`)
      formik.setFieldError(`${name}`, 'Already included')
      return
    }
    formik.setFieldError(`${name}`, '')
    includedList.push(formik.values[name])

    formik.setFieldValue(`${fieldName}`, includedList, true)
    formik.setFieldValue(`${name}`, '')
  }

  const removeEanAsin = (eanAsin: string, fieldName: string) => {
    const includedList = formik.values[fieldName].filter(
      (item: string) => item !== eanAsin
    )
    formik.setFieldValue(`${fieldName}`, includedList, true)
  }

  return (
    <Container fluid>
      {localStorage.getItem('disclaimer') && (
        <Alert
          style={{
            zIndex: 1000000,
            alignSelf: 'center',
            textAlign: 'center',
          }}
          color="danger"
        >
          <h1>{localStorage.getItem('disclaimer')}</h1>
        </Alert>
      )}
      <ToastContainer />
      <Form onSubmit={formik.handleSubmit}>
        <Row form>
          <Col>
            <Label for="profitUnitMin">PROFIT UNITAIRE</Label>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="profitUnitMin">MIN</Label>
                  <Input
                    invalid={
                      !!(
                        formik.errors.profitUnitMin &&
                        formik.touched.profitUnitMin
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="profitUnitMin"
                    id="profitUnitMin"
                    value={formik.values.profitUnitMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="profitUnitMax">MAX</Label>
                  <Input
                    invalid={
                      !!(
                        formik.errors.profitUnitMax &&
                        formik.touched.profitUnitMax
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="profitUnitMax"
                    id="profitUnitMax"
                    value={formik.values.profitUnitMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Label for="profitMonthMinprofitMonthMax">PROFIT PAR MOIS</Label>
              <Col>
                <FormGroup>
                  <Label for="profitMonthMin">MIN</Label>
                  <Input
                    invalid={
                      !!(
                        formik.errors.profitMonthMin &&
                        formik.touched.profitMonthMin
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="profitMonthMin"
                    id="profitMonthMin"
                    value={formik.values.profitMonthMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="profitMonthMax">MAX</Label>
                  <Input
                    invalid={
                      !!(
                        formik.errors.profitMonthMax &&
                        formik.touched.profitMonthMax
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="profitMonthMax"
                    id="profitMonthMax"
                    value={formik.values.profitMonthMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="ml-3 form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  onChange={(e) => {
                    formik.setFieldValue('amazonNotSeller', e.target.checked)
                  }}
                  checked={formik.values.amazonNotSeller}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  AMAZON N'EST PAS VENDEUR
                </label>
              </div>
            </Row>
            <Row>
              <div className="ml-3 form-check form-switch form-switch-lg mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  onChange={(e) => {
                    formik.setFieldValue('isGrouped', e.target.checked)
                  }}
                  checked={formik.values.isGrouped}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  Exclure les Produits en lot
                </label>
              </div>
            </Row>
          </Col>
          <Col>
            <Row>
              <FormGroup>
                <Label for="marketplace">MARKETPLACE</Label>
                {/* <Select
                  value={formik.values.marketplace}
                  isMulti
                  onChange={() => {
                    // handleMulti(selectedMulti)
                  }}
                  options={Marketplaces}
                  className="select2-selection"
                /> */}
                <Input
                  invalid={!!(formik.errors.marketplace && formik.touched)}
                  onBlur={formik.handleBlur}
                  type="select"
                  name="marketplace"
                  id="marketplace"
                  value={formik.values.marketplace}
                  onChange={formik.handleChange}
                >
                  <option value="">TOUT</option>
                  {Marketplaces.map((market) => (
                    <option
                      key={`marketPlace-${market.label}`}
                      value={market.value}
                    >
                      {market.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Row>
            {/* <Row>
              <FormGroup>
                <Label for="category">CATÉGORIE</Label>
                <Input
                  onBlur={formik.handleBlur}
                  type="select"
                  name="category"
                  id="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                >
                  <option value="">TOUT</option>
                </Input>
              </FormGroup>
            </Row> */}
            <Row form>
              <FormGroup>
                <Label for="eanAsinList">LISTE ASIN</Label>
                <div className="input-group">
                  <Input
                    invalid={
                      !!(
                        formik.errors.eanAsinList && formik.touched.eanAsinList
                      )
                    }
                    value={formik.values.eanAsinList}
                    onBlur={formik.handleBlur}
                    type="text"
                    className="form-control"
                    id="inputGroupFile04"
                    name="eanAsinList"
                    onChange={formik.handleChange}
                  />
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'eanAsinList',
                        'includedEanAsinList',
                        'ASIN'
                      )
                    }}
                    className="btn btn-primary"
                    type="button"
                    id="inputGroupFileAddon04"
                    style={{ marginRight: 2 }}
                  >
                    INCLUS
                  </button>
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'eanAsinList',
                        'excludedEanAsinList',
                        'ASIN'
                      )
                    }}
                    className="btn btn-secondary ml-1"
                    type="button"
                    id="inputGroupFileAddon04"
                  >
                    EXCLUS
                  </button>
                </div>
              </FormGroup>
              <div>
                {formik.values.includedEanAsinList.map((eanAsin, index) => (
                  <button
                    onClick={() => {
                      removeEanAsin(eanAsin, 'includedEanAsinList')
                    }}
                    key={eanAsin + index}
                    type="button"
                    className="btn btn-success btn-sm btn-rounded"
                  >
                    {eanAsin}{' '}
                    <i className="bx bx-window-close font-size-16 align-middle me-2" />
                  </button>
                ))}
              </div>
              <div style={{ marginTop: 5 }}>
                {formik.values.excludedEanAsinList.map((eanAsin) => (
                  <button
                    onClick={() => {
                      removeEanAsin(eanAsin, 'excludedEanAsinList')
                    }}
                    key={`${eanAsin}excluded`}
                    type="button"
                    className="btn btn-danger btn-sm btn-rounded"
                  >
                    {eanAsin}{' '}
                    <i className="bx bx-window-close font-size-16 align-middle me-2" />
                  </button>
                ))}
              </div>
              {/* Additional columns for Price Amazon Min and Max */}
            </Row>
            <Row form>
              <FormGroup>
                <Label for="eanAsinList">MOTS-CLÉS INCLUS/EXCLUS</Label>
                <div className="input-group">
                  <Input
                    invalid={
                      !!(
                        formik.errors.keywordList && formik.touched.keywordList
                      )
                    }
                    value={formik.values.keywordList}
                    onBlur={formik.handleBlur}
                    type="text"
                    className="form-control"
                    id="inputGroupFile04"
                    name="keywordList"
                    onChange={formik.handleChange}
                  />
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'keywordList',
                        'includedKeywordList',
                        'MOTS-CLÉS'
                      )
                    }}
                    className="btn btn-primary"
                    type="button"
                    id="inputGroupFileAddon04"
                    style={{ marginRight: 2 }}
                  >
                    INCLUS
                  </button>
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'keywordList',
                        'excludedKeywordList',
                        'MOTS-CLÉS'
                      )
                    }}
                    className="btn btn-secondary ml-1"
                    type="button"
                    id="inputGroupFileAddon04"
                  >
                    EXCLUS
                  </button>
                </div>
              </FormGroup>
              <div>
                {formik.values.includedKeywordList.map((eanAsin, index) => (
                  <button
                    onClick={() => {
                      removeEanAsin(eanAsin, 'includedKeywordList')
                    }}
                    key={eanAsin + index}
                    type="button"
                    className="btn btn-success btn-sm btn-rounded"
                  >
                    {eanAsin}{' '}
                    <i className="bx bx-window-close font-size-16 align-middle me-2" />
                  </button>
                ))}
              </div>
              <div style={{ marginTop: 5 }}>
                {formik.values.excludedKeywordList.map((eanAsin) => (
                  <button
                    onClick={() => {
                      removeEanAsin(eanAsin, 'excludedKeywordList')
                    }}
                    key={`${eanAsin}excluded`}
                    type="button"
                    className="btn btn-danger btn-sm btn-rounded"
                  >
                    {eanAsin}{' '}
                    <i className="bx bx-window-close font-size-16 align-middle me-2" />
                  </button>
                ))}
              </div>
              {/* Additional columns for Price Amazon Min and Max */}
            </Row>
          </Col>
          <Col>
            <Row form>
              <Label for="priceAmazonMin">PRIX AMAZON</Label>

              <Col>
                <FormGroup>
                  <Label for="priceAmazonMin">MIN</Label>
                  <Input
                    invalid={
                      !!(
                        formik.errors.priceAmazonMin &&
                        formik.touched.priceAmazonMin
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="priceAmazonMin"
                    id="priceAmazonMin"
                    placeholder="MIN"
                    value={formik.values.priceAmazonMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="priceAmazonMax">MAX</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    invalid={
                      !!(
                        formik.errors.priceAmazonMax &&
                        formik.touched.priceAmazonMax
                      )
                    }
                    type="number"
                    name="priceAmazonMax"
                    id="priceAmazonMax"
                    placeholder="MAX"
                    value={formik.values.priceAmazonMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Label for="roiUnitMin">ROI UNITAIRE</Label>
              <Col>
                <FormGroup>
                  <Label for="roiUnitMin">MIN</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    invalid={
                      !!(formik.errors.roiUnitMin && formik.touched.roiUnitMin)
                    }
                    type="number"
                    name="roiUnitMin"
                    id="roiUnitMin"
                    placeholder="MIN"
                    value={formik.values.roiUnitMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="roiUnitMax">MAX</Label>
                  <Input
                    invalid={
                      !!(formik.errors.roiUnitMax && formik.touched.roiUnitMax)
                    }
                    onBlur={formik.handleBlur}
                    type="number"
                    name="roiUnitMax"
                    id="roiUnitMax"
                    placeholder="MAX"
                    value={formik.values.roiUnitMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Label for="salesPerMonthMinsalesPerMonthMax">
                VENTES PAR MOIS
              </Label>
              <Col>
                <FormGroup>
                  <Label for="salesPerMonthMin">MIN</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    invalid={
                      !!(
                        formik.errors.salesPerMonthMin &&
                        formik.touched.salesPerMonthMin
                      )
                    }
                    type="number"
                    name="salesPerMonthMin"
                    id="salesPerMonthMin"
                    placeholder="MIN"
                    value={formik.values.salesPerMonthMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="salesPerMonthMax">MAX</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    invalid={
                      !!(
                        formik.errors.salesPerMonthMax &&
                        formik.touched.salesPerMonthMax
                      )
                    }
                    type="number"
                    name="salesPerMonthMax"
                    id="salesPerMonthMax"
                    placeholder="MIN"
                    value={formik.values.salesPerMonthMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <FormGroup>
                <Label for="supplierType">TYPE DE FOURNISSEUR</Label>
                <Input
                  onBlur={formik.handleBlur}
                  type="select"
                  name="supplierType"
                  id="supplierType"
                  value={formik.values.supplierType}
                  onChange={handleSupplierChange}
                >
                  {/* Add more options for other supplier types */}
                  <option value="supplier">Sites marchands</option>
                  <option value="pro">
                    Sites professionnels
                    {(user.hasFreePlan || user.hasBasicPlan) &&
                    !user.roles.includes('ADMIN') ? (
                      <> &#128274;</>
                    ) : null}
                  </option>
                </Input>
              </FormGroup>
            </Row>
            <Row form>
              <FormGroup>
                <Label for="eanAsinList">LISTE EAN/UPC</Label>
                <div className="input-group">
                  <Input
                    invalid={
                      !!(
                        formik.errors.supplierEanAsinList &&
                        formik.touched.supplierEanAsinList
                      )
                    }
                    value={formik.values.supplierEanAsinList}
                    onBlur={formik.handleBlur}
                    type="text"
                    className="form-control"
                    id="inputGroupFile04"
                    name="supplierEanAsinList"
                    onChange={formik.handleChange}
                  />
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'supplierEanAsinList',
                        'supplierIncludedEanAsinList',
                        'EAN/UPC'
                      )
                    }}
                    className="btn btn-primary"
                    type="button"
                    id="inputGroupFileAddon04"
                    style={{ marginRight: 2 }}
                  >
                    INCLUS
                  </button>
                  <button
                    onClick={() => {
                      handleEanAsinListChange(
                        'supplierEanAsinList',
                        'supplierExcludedEanAsinList',
                        'EAN/UPC'
                      )
                    }}
                    className="btn btn-secondary ml-1"
                    type="button"
                    id="inputGroupFileAddon04"
                  >
                    EXCLUS
                  </button>
                </div>
              </FormGroup>
              <div>
                {formik.values.supplierIncludedEanAsinList.map(
                  (eanAsin, index) => (
                    <button
                      onClick={() => {
                        removeEanAsin(eanAsin, 'supplierIncludedEanAsinList')
                      }}
                      key={eanAsin + index}
                      type="button"
                      className="btn btn-success btn-sm btn-rounded"
                    >
                      {eanAsin}{' '}
                      <i className="bx bx-window-close font-size-16 align-middle me-2" />
                    </button>
                  )
                )}
              </div>
              <div style={{ marginTop: 5 }}>
                {formik.values.supplierExcludedEanAsinList.map((eanAsin) => (
                  <button
                    onClick={() => {
                      removeEanAsin(eanAsin, 'supplierExcludedEanAsinList')
                    }}
                    key={`${eanAsin}excluded`}
                    type="button"
                    className="btn btn-danger btn-sm btn-rounded"
                  >
                    {eanAsin}{' '}
                    <i className="bx bx-window-close font-size-16 align-middle me-2" />
                  </button>
                ))}
              </div>
              {/* Additional columns for Price Amazon Min and Max */}
            </Row>
            <Row>
              <FormGroup>
                <Label for="suppliers">FOURNISSEURS</Label>
                <Select
                  isMulti
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      border: '1px solid #ced4da',
                      borderRadius: 0,
                      boxShadow: state.isFocused
                        ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                        : 'none',
                      '&:hover': {
                        border: '1px solid #ced4da',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? '#fff' : '#000',
                      zIndex: 999,
                      backgroundColor: state.isSelected
                        ? '#0d6efd'
                        : state.isFocused
                        ? '#e9ecef'
                        : '#fff',
                    }),
                  }}
                  value={formik.values.suppliers}
                  onChange={(e) => {
                    // handleMulti(selectedMulti)
                    if (e.length > 3) {
                      toastr.error(
                        'Vous ne pouvez pas sélectionner plus de 3 fournisseurs',
                        'Fournisseurs'
                      )
                      return
                    }

                    formik.setFieldValue('suppliers', e, true)
                  }}
                  options={(user.hasFreePlan
                    ? []
                    : supplerIndexByType[formik.values.supplierType] || []
                  ).map((supplier) => ({
                    value: supplier._id,
                    label: supplier.name,
                  }))}
                  className="select2-selection"
                />

                {/* <Input
                  onBlur={formik.handleBlur}
                  type="select"
                  name="suppliers"
                  id="suppliers"
                  value={formik.values.suppliers}
                  onChange={formik.handleChange}
                >
                  <option value="">TOUT</option>
                  {(supplerIndexByType[formik.values.supplierType] || []).map(
                    (supplier: any) => (
                      <option key={supplier._id} value={supplier._id}>
                        {supplier.name}
                      </option>
                    ))}
                </Input> */}
              </FormGroup>
            </Row>

            {/* <Row>
              <FormGroup>
                <Label for="suppliers">PAYS DU FOURNISSEURS</Label>
                <Input
                  onBlur={formik.handleBlur}
                  type="select"
                  name="supplierCountry"
                  id="supplierCountry"
                  value={formik.values.supplierCountry}
                  onChange={formik.handleChange}
                >
                  <option value="">Tout</option>
                  <option value="FR">France</option>
                  <option value="UK">UK</option>
                  <option value="DE">Allemagne</option>
                  <option value="IT">Italie</option>
                  <option value="ES">Espagne</option>
                  <option value="PL">Pologne</option>
                  <option value="BE">Belgique</option>
                  <option value="NL">Pays-Bas</option>

                </Input>
              </FormGroup>
            </Row> */}
          </Col>
          <Col>
            <Row form>
              <Label for="supplierPriceMinsupplierPriceMax">
                PRIX FOURNISSEUR
              </Label>

              <Col>
                <FormGroup>
                  <Label for="supplierPriceMin">MIN</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    type="number"
                    name="supplierPriceMin"
                    id="supplierPriceMin"
                    placeholder="MIN"
                    value={formik.values.supplierPriceMin}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="supplierPriceMax">MAX</Label>
                  <Input
                    onBlur={formik.handleBlur}
                    type="number"
                    name="supplierPriceMax"
                    id="supplierPriceMax"
                    placeholder="MIN"
                    value={formik.values.supplierPriceMax}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <Button
                  disabled={
                    user.plan === 'FREE' && !user.roles.includes('ADMIN')
                  }
                  type="submit"
                  color="primary"
                >
                  RECHERCHE
                </Button>{' '}
                <Button
                  disabled={
                    user.plan === 'FREE' && !user.roles.includes('ADMIN')
                  }
                  onClick={onCSVParse}
                  color="secondary"
                >
                  EXPORT CSV
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export { Filters }
