import { useQuery } from 'common/utils'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Store from 'slices'

const VerifyEmail = () => {
  const navgiation = useNavigate()
  const query = useQuery()
  const dispatch = useDispatch()
  const token = query.get('token')

  React.useEffect(() => {
    if (!token) {
      navgiation('/login')
    }
    dispatch(Store.login.emailValidation(token as string, navgiation) as any)
  }, [token])

  return (
    <div>
      <h1>Verify Email</h1>
    </div>
  )
}

export { VerifyEmail }
