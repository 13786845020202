import { ResultTable } from 'Components/ResultTable/ResultTable'
import { ASIN_REGEX, COUNTRY_FLAGS, EAN_REGEX, UPC_REXEX } from 'common/utils'
import React, { useEffect } from 'react'
import {
  Alert,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap'
import * as data from 'data'
import * as R from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import { getSearchConditionMongoose } from 'pages/Dashboard/searchHelper'

const ReverseSearch = () => {
  const dispatch = useDispatch()
  const suppliers = useSelector(Store.suppliers.selectors.getAllCollection)
  const supplerIndexByType = R.groupBy(R.prop('type') as any, suppliers)
  const [conditions, setConditions] = React.useState<any>({})
  const user: any = useSelector(Store.login.selectors.user)
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [result, setResult] = React.useState<any>({})
  const [isValid, setIsValid] = React.useState(true)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [eanAsinList, setEanAsinList] = React.useState<string[]>([])
  const [excludedEanAsinList, setEExcludedEanAsinList] = React.useState<
    string[]
  >([])
  useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers() as any)
  }, [])
  const onChangeText = (value: string) => {
    if (
      EAN_REGEX.test(value) ||
      ASIN_REGEX.test(value) ||
      UPC_REXEX.test(value)
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
    setInputValue(value)
  }
  const onInclude = () => {
    if (eanAsinList.includes(inputValue)) {
      return
    }
    if (eanAsinList.length >= 3) {
      toastr.error(
        'Vous ne pouvez pas sélectionner plus de 3 ASIN/EAN',
        'Fournisseurs'
      )
      return
    }
    setEanAsinList([...eanAsinList, inputValue])
    onChangeText('')
  }
  const onExclude = () => {
    if (excludedEanAsinList.includes(inputValue)) {
      return
    }
    if (eanAsinList.includes(inputValue)) {
      setEanAsinList(eanAsinList.filter((ean) => ean !== inputValue))
    }
    setEExcludedEanAsinList([...excludedEanAsinList, inputValue])
    onChangeText('')
  }
  const removeEanAsin = (eanAsin: string, list: string) => {
    if (list === 'eanAsinList') {
      setEanAsinList(eanAsinList.filter((ean) => ean !== eanAsin))
    } else {
      setEExcludedEanAsinList(
        excludedEanAsinList.filter((ean) => ean !== eanAsin)
      )
    }
  }

  const paginateSearch = (page: number, poConditions?: any) => {
    if (
      !conditions ||
      (eanAsinList.length === 0 && excludedEanAsinList.length === 0)
    ) {
      return
    }
    setLoading(true)
    data.product
      .findByConditions('search', {
        conditions: poConditions || conditions,
        paginationOption: {
          page,
        },
        sort: {
          profit: -1,
          lastUpdateDate: -1,
        },
        group: {
          _id: '$asin',
          products: {
            $push: '$$ROOT',
          },
          count: {
            $sum: 1,
          },
        },
      })
      .then((res) => {
        setResult(res.data)

        setLoading(false)
      })
      .catch((err) => {
        console.log('err', err)
        if (err === 'Invalid credentials') {
          dispatch(Store.login.logoutUser() as any)
        }
        setLoading(false)
      })
  }
  const onSubmit = async () => {
    const searchConditions = getSearchConditionMongoose(
      {
        includedEanAsinList: eanAsinList,
        excludedEanAsinList,
      },
      supplerIndexByType
    )
    setConditions(searchConditions)
    paginateSearch(1, searchConditions)
  }
  console.log({ result })

  return (
    <div className="page-content">
      <Container fluid>
        {localStorage.getItem('disclaimer') && (
          <Alert
            style={{
              zIndex: 1000000,
              alignSelf: 'center',
              textAlign: 'center',
            }}
            color="danger"
          >
            <h1>{localStorage.getItem('disclaimer')}</h1>
          </Alert>
        )}
        <h1>Reverse Search</h1>
        <Row form>
          <FormGroup>
            <Label for="eanAsinList">LISTE EAN/ASIN</Label>
            <div className="input-group">
              <Input
                // onBlur={formik.handleBlur}
                value={inputValue}
                invalid={!isValid}
                type="text"
                className="form-control"
                id="inputGroupFile04"
                name="eanAsinList"
                onChange={(e) => onChangeText(e.target.value)}
                // onChange={formik.handleChange}
              />
              <button
                disabled={isValid === false || excludedEanAsinList.length > 0}
                onClick={onInclude}
                className="btn btn-primary mr-2"
                type="button"
                id="inputGroupFileAddon04"
              >
                INCLUS
              </button>
              <button
                disabled={isValid === false || eanAsinList.length > 0}
                onClick={onExclude}
                className="btn btn-secondary"
                type="button"
                id="inputGroupFileAddon04"
              >
                EXCLUS
              </button>
            </div>
          </FormGroup>
        </Row>
        <Row className="mb-2">
          {eanAsinList.length > 0 && (
            <div className="mt-2">
              {eanAsinList.map((eanAsin) => (
                <button
                  onClick={() => {
                    removeEanAsin(eanAsin, 'eanAsinList')
                  }}
                  key={`${eanAsin}_included_`}
                  type="button"
                  className="btn btn-success btn-sm btn-rounded mr-2"
                >
                  {eanAsin}{' '}
                  <i className="bx bx-window-close font-size-16 align-middle me-2" />
                </button>
              ))}
            </div>
          )}
          {excludedEanAsinList.length > 0 && (
            <div className="mt-2">
              {excludedEanAsinList.map((eanAsin) => (
                <button
                  onClick={() => {
                    removeEanAsin(eanAsin, 'excludedEanAsinList')
                  }}
                  key={`${eanAsin}_included_`}
                  type="button"
                  className="btn btn-danger btn-sm btn-rounded mr-2"
                >
                  {eanAsin}{' '}
                  <i className="bx bx-window-close font-size-16 align-middle me-2" />
                </button>
              ))}
            </div>
          )}
        </Row>
        <div className="text-center mb-5">
          <button
            disabled={
              ((eanAsinList.length === 0 && excludedEanAsinList.length === 0) ||
                user?.plan === 'FREE') &&
              !user.roles.includes('ADMIN')
            }
            type="button"
            onClick={onSubmit}
            className="btn btn-primary btn-md"
          >
            RECHERCHER
          </button>
        </div>
        <Row>
          <ResultTable
            allowNonProfit
            tableResult={result.docs || []}
            isLoading={isLoading}
          />
        </Row>
      </Container>
    </div>
  )
}

export { ReverseSearch }
