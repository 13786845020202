import React, { useState, useEffect } from 'react'
import { Row, Col, Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'
import classname from 'classnames'
import { withTranslation } from 'react-i18next'
import withRouter from '../../Components/Common/withRouter'

// i18n

const Navbar = (props: any) => {
  const [dashboard, setdashboard] = useState<boolean>(false)
  const [ui, setui] = useState<boolean>(false)
  const [app, setapp] = useState<boolean>(false)
  const [email, setemail] = useState<boolean>(false)
  const [ecommerce, setecommerce] = useState<boolean>(false)
  const [crypto, setcrypto] = useState<boolean>(false)
  const [project, setproject] = useState<boolean>(false)
  const [task, settask] = useState<boolean>(false)
  const [contact, setcontact] = useState<boolean>(false)
  const [blog, setBlog] = useState<boolean>(false)
  const [job, setJob] = useState<boolean>(false)
  const [candidate, setCandidate] = useState<boolean>(false)
  const [component, setcomponent] = useState<boolean>(false)
  const [form, setform] = useState<boolean>(false)
  const [table, settable] = useState<boolean>(false)
  const [chart, setchart] = useState<boolean>(false)
  const [icon, seticon] = useState<boolean>(false)
  const [map, setmap] = useState<boolean>(false)
  const [extra, setextra] = useState<boolean>(false)
  const [invoice, setinvoice] = useState<boolean>(false)
  const [auth, setauth] = useState<boolean>(false)
  const [utility, setutility] = useState<boolean>(false)

  useEffect(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul: any = document.getElementById('navigation')
    const items = ul.getElementsByTagName('a')

    const itemsArray = [...items]
    removeActivation(itemsArray)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname])

  function activateParentDropdown(item: any) {
    item.classList.add('active')
    const parent = item.parentElement
    if (parent) {
      parent.classList.add('active') // li
      const parent2 = parent.parentElement
      parent2.classList.add('active') // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add('active') // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add('active') // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add('active') // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add('active') // li
            }
          }
        }
      }
    }
    return false
  }

  const removeActivation = (items: any) => {
    const actiItems = items.filter((x: any) => x.classList.contains('active'))

    actiItems.forEach((item: any) => {
      const parent = item.parentElement

      if (item.classList.contains('dropdown-item')) {
        parent.classList.remove('active')
        const parent2 = parent.parentElement
        parent2.classList.remove('active')

        if (!item.classList.contains('active')) {
          item.setAttribute('aria-expanded', false)
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show')
        }
      }
      if (item.classList.contains('nav-link')) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show')
        }
        item.setAttribute('aria-expanded', false)
      }
      item.classList.remove('active')
    })
  }

  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={(e) => {
                    e.preventDefault()
                    setdashboard(!dashboard)
                  }}
                  to="/dashboard"
                >
                  <i className="bx bx-home-circle me-2" />
                  {props.t('Dashboard')} {props.menuOpen}
                  <div className="arrow-down" />
                </Link>
                <div
                  className={classname('dropdown-menu', { show: dashboard })}
                >
                  <Link to="/dashboard" className="dropdown-item">
                    {props.t('Default')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('Saas')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('Crypto')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('Blog')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('Jobs')}
                  </Link>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setui(!ui)
                  }}
                  className="nav-link dropdown-toggle arrow-none"
                >
                  <i className="bx bx-tone me-2" />
                  {props.t('UI Elements')} <div className="arrow-down" />
                </Link>
                <div
                  className={classname(
                    'dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl',
                    { show: ui }
                  )}
                >
                  <Row>
                    <Col lg={4}>
                      <div>
                        <Link to="#" className="dropdown-item">
                          {props.t('Alerts')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Buttons')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Cards')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Carousel')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Dropdowns')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Grid')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Images')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Lightbox')}
                        </Link>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div>
                        <Link to="#" className="dropdown-item">
                          {props.t('Modals')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Offcanvas')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Range Slider')}
                        </Link>
                        {/* <Link
                            to="#"
                            className="dropdown-item"
                          >
                            {props.t("Session Timeout")}
                          </Link> */}
                        <Link to="#" className="dropdown-item">
                          {props.t('Progress Bars')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Placeholders')}
                        </Link>
                        {/* <Link to="#" className="dropdown-item">
                              {props.t("Sweet-Alert")}
                            </Link> */}
                        <Link to="#" className="dropdown-item">
                          {props.t('Tabs & Accordions')}
                        </Link>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div>
                        <Link to="#" className="dropdown-item">
                          {props.t('Typography')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Toasts')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Video')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('General')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Colors')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Rating')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Notifications')}
                        </Link>
                        <Link to="#" className="dropdown-item">
                          {props.t('Utilities')}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setapp(!app)
                  }}
                  className="nav-link dropdown-togglez arrow-none"
                >
                  <i className="bx bx-customize me-2" />
                  {props.t('Apps')} <div className="arrow-down" />
                </Link>
                <div className={classname('dropdown-menu', { show: app })}>
                  <Link to="#" className="dropdown-item">
                    {props.t('Calendar')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('Chat')}
                  </Link>
                  <Link to="#" className="dropdown-item">
                    {props.t('File Manager')}
                  </Link>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setemail(!email)
                      }}
                    >
                      {props.t('Email')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', { show: email })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Inbox')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Read Email')}
                      </Link>
                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault()
                            setemail(!email)
                          }}
                        >
                          <span key="t-email-templates">Templates</span>{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: email,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t('Basic Action')}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t('Alert Email')}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t('Billing Email')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setecommerce(!ecommerce)
                      }}
                    >
                      {props.t(' Ecommerce')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: ecommerce,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Products')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Product Detail')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Orders')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Customers')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Cart')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Checkout')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Shops')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Add Product')}
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setcrypto(!crypto)
                      }}
                    >
                      {props.t('Crypto')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', { show: crypto })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Wallet')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Buy/Sell')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Exchange')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Lending')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Orders')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('KYC Application')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('ICO Landing')}
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setproject(!project)
                      }}
                    >
                      {props.t('Projects')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: project,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Projects Grid')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Projects List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Project Overview')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Create New')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        settask(!task)
                      }}
                    >
                      {props.t('Tasks')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: task })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Task List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Tasks Kanban')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Create Task')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setcontact(!contact)
                      }}
                    >
                      {props.t('Contacts')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: contact,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('User Grid')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('User List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Profile')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setBlog(!blog)
                      }}
                    >
                      {props.t('Blog')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: blog,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Blog List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Blog Grid')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Blog Details')}
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setJob(!job)
                      }}
                    >
                      {props.t('Jobs')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: job })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Job List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Job Grid')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Job Apply')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Job Details')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Job Catogories')}
                      </Link>

                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault()
                            setCandidate(!candidate)
                          }}
                        >
                          <span key="t-candidate">Candidate</span>{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: candidate,
                          })}
                        >
                          <Link to="#" className="dropdown-item">
                            {props.t('List')}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            {props.t('Overview')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle arrow-none"
                  onClick={(e) => {
                    e.preventDefault()
                    setcomponent(!component)
                  }}
                >
                  <i className="bx bx-collection me-2" />
                  {props.t('Components')} <div className="arrow-down" />
                </Link>
                <div
                  className={classname('dropdown-menu', { show: component })}
                >
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setform(!form)
                      }}
                    >
                      {props.t('Forms')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: form })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Elements')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Layouts')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Validation')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Advanced')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Editors')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form File Upload')}{' '}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Xeditable')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Repeater')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Wizard')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Form Mask')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        settable(!table)
                      }}
                    >
                      {props.t('Tables')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', { show: table })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Basic Tables')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Data Tables')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setchart(!chart)
                      }}
                    >
                      {props.t('Charts')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', { show: chart })}
                    >
                      <Link to="#" className="dropdown-item">
                        {' '}
                        {props.t('Apex charts')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {' '}
                        {props.t('E Chart')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {' '}
                        {props.t('Chartjs Chart')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Re Chart')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {' '}
                        {props.t('Sparkline Chart')}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        seticon(!icon)
                      }}
                    >
                      {props.t('Icons')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: icon })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Boxicons')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Material Design')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Dripicons')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Font awesome')}{' '}
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setmap(!map)
                      }}
                    >
                      {props.t('Maps')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: map })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Google Maps')}{' '}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Vector Maps')}{' '}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Leaflet Maps')}{' '}
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setextra(!extra)
                  }}
                >
                  <i className="bx bx-file me-2" />
                  {props.t('Extra pages')} <div className="arrow-down" />
                </Link>
                <div className={classname('dropdown-menu', { show: extra })}>
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setinvoice(!invoice)
                      }}
                    >
                      {props.t('Invoices')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: invoice,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Invoice List')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Invoice Detail')}
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle arrow-none"
                      onClick={(e) => {
                        e.preventDefault()
                        setauth(!auth)
                      }}
                    >
                      {props.t('Authentication')} <div className="arrow-down" />
                    </Link>
                    <div className={classname('dropdown-menu', { show: auth })}>
                      <Link to="#" className="dropdown-item">
                        {props.t('Login')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Login 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Register')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Register 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Recover Password')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Recover Password 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Lock Screen')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Lock Screen 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Confirm Mail')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Confirm Mail 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Email Verification')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Email Verification 2')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Two Step Verification')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Two Step Verification 2')}
                      </Link>
                    </div>
                  </div>

                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      to="#"
                      onClick={(e) => {
                        e.preventDefault()
                        setutility(!utility)
                      }}
                    >
                      {props.t('Utility')} <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname('dropdown-menu', {
                        show: utility,
                      })}
                    >
                      <Link to="#" className="dropdown-item">
                        {props.t('Starter Page')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Maintenance')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Coming Soon')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Timeline')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('FAQs')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Pricing')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Error 404')}
                      </Link>
                      <Link to="#" className="dropdown-item">
                        {props.t('Error 500')}
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  )
}
export default withRouter(withTranslation()(Navbar))
