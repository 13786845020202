import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// redux
import { useSelector, useDispatch } from 'react-redux'

import withRouter from 'Components/Common/withRouter'

// Import Breadcrumb
import Breadcrumb from 'Components/Common/Breadcrumb'

import { editProfile, resetProfileFlag } from 'slices/thunk'
import { createSelector } from 'reselect'
import avatar from '../../assets/images/users/avatar-1.jpg'

const UserProfile = () => {
  // meta title
  document.title = 'Profile | ResellCorner'

  const dispatch = useDispatch<any>()

  const [email, setemail] = useState('admin@gmail.com')
  const [name, setname] = useState(null)
  const [idx, setidx] = useState(1)

  const selectProperties = createSelector(
    (state: any) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success,
    })
  )

  const { error, success } = useSelector(selectProperties)

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser') || '{}')
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        setname(obj.username || obj.providerData.displayName)
        setemail(obj.email || obj.providerData.email)
        setidx(obj.uid || obj.providerData.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || 'admin',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your UserName'),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values))
    },
  })

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Skote" breadcrumbItem="Profile" />

        <Row>
          <Col lg="12">
            {error && error ? <Alert color="danger">{error}</Alert> : null}
            {success ? <Alert color="success">{success}</Alert> : null}

            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="ms-3">
                    <img
                      src={avatar}
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h5>{name || 'admin'}</h5>
                      <p className="mb-1">{email}</p>
                      <p className="mb-0">Id no: #{idx}</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <h4 className="card-title mb-4">Change User Name</h4>

        <Card>
          <CardBody>
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="form-group">
                <Label className="form-label">User Name</Label>
                <Input
                  name="username"
                  className="form-control"
                  placeholder="Enter User Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ''}
                  invalid={
                    !!(
                      validation.touched.username && validation.errors.username
                    )
                  }
                />
                {validation.touched.username && validation.errors.username ? (
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                ) : null}
                <Input name="idx" value={idx} type="hidden" />
              </div>
              <div className="text-center mt-4">
                <Button type="submit" color="danger">
                  Update User Name
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default withRouter(UserProfile)
