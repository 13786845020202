import React from 'react'
import { Link } from 'react-router-dom'

// import components
import SidebarContent from './SidebarContent'

// import images
import logo from '../../assets/images/logoR.png'
import logoLightPng from '../../assets/images/logolg.png'
import logoLightSvg from '../../assets/images/logoR.png'
import logoDark from '../../assets/images/logolg.png'

const Sidebar = (props: any) => {
  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src={logo} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" height="32" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src={logoLightSvg} alt="" height="25" />
          </span>
          <span className="logo-lg">
            <img src={logoLightPng} alt="" height="32" />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
      </div>
      <div className="sidebar-background" />
    </div>
  )
}

export default Sidebar
