import { Navigate } from 'react-router-dom'

// Auth
import Login from 'pages/Authentication/login'
import Logout from 'pages/Authentication/Logout'
import UserProfile from 'pages/Authentication/user-profile'
import ForgotPassword from 'pages/Authentication/ForgotPassword'
import SignUp from 'pages/Authentication/Register'
import UsersTable from 'pages/UserTable/UserTable'
import EditUser from 'pages/EditUser/EditUser'
import { AmazonAPI } from 'pages/AmazonAPI/AmazonAPI'
import { UpdateProfile } from 'pages/UpdateProfile/UpdateProfile'
import { ContactSupport } from 'pages/ContactSupport/ContactSupport'
import { Tuto } from 'pages/Tuto/Tuto'
import { ReverseSearch } from 'pages/ReverseSearch/ReverseSearch'
import { Supplier } from 'pages/Supplier/Supplier'
import { Plan } from 'pages/Plan/Plan'
import { Invoices } from 'pages/Invoices/Invoices'
import ResetPassword from 'pages/Authentication/ResetPassword'
import { VerifyEmail } from 'pages/Authentication/VerfiyEmail'
import { DisclaimerPage } from 'pages/DisclaimerPage/DisclaimerPage'
import Dashboard from '../pages/Dashboard'

const authProtectedRoutes = [
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/reverse', component: <ReverseSearch /> },
  { path: '/profile', component: <UserProfile /> },
  { path: '/users', component: <UsersTable /> },
  { path: '/users/:userId', component: <EditUser /> },
  { path: '/amazonapi', component: <AmazonAPI /> },
  { path: '/suppliers', component: <Supplier /> },
  { path: '/update-user', component: <UpdateProfile /> },
  { path: '/plan', component: <Plan /> },
  { path: '/suppliers', component: <Supplier /> },
  { path: '/invoices', component: <Invoices /> },
  { path: '/', exact: true, component: <Navigate to="/dashboard" /> },
  { path: '/support', component: <ContactSupport /> },
  { path: '/disclaimer', component: <DisclaimerPage /> },
]

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/logout', component: <Logout /> },
  { path: '/forgot-password', component: <ForgotPassword /> },
  { path: '/reset-password', component: <ResetPassword /> },
  { path: '/register', component: <SignUp /> },
  { path: '/tutorial', component: <Tuto /> },
  { path: '/verify-email', component: <VerifyEmail /> },
]
export { authProtectedRoutes, publicRoutes }
