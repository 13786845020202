import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Row, Label } from 'reactstrap'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { TUser } from 'data/UserEntity'
import * as data from '../../data'
import * as store from '../../slices'

const UsersTable = () => {
  const navigate = useNavigate()
  const [requestCount, setRequestCount] = useState({})
  const users: TUser[] = useSelector(
    store.user.selectors.getAllCollection
  ) as TUser[]

  const dispatch = useDispatch()
  const isAdmin = useSelector(store.login.selectors.isAdmin)

  useEffect(() => {
    dispatch(store.user.fetchUsers() as any)
    data.user
      .get('request-count')
      .then((res) => {
        setRequestCount(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const handleEdit = (userId) => {
    navigate(`/users/${userId}`)
    // Logic to handle user edit
  }

  const removeAdmin = (userId) => {
    dispatch(store.user.toggleAdmin(userId, false) as any)
  }
  const setAdmin = (userId) => {
    dispatch(store.user.toggleAdmin(userId, true) as any)
  }

  const handleDelete = (userId) => {
    // dispatch(store.user.deleteUser(userId) as any)
    console.log('delete user', userId)
  }
  const requestPerMinutes = (request: any) => {
    const firstRequest = new Date(request.firstRequest)
    const lastRequest = new Date(request.lastRequest)
    const diff = lastRequest.getTime() - firstRequest.getTime()

    const minutes = Math.floor(diff / 1000 / 60)
    const count = minutes ? Math.floor(request.count / minutes) : request.count
    return `${count || 0} / min`
  }
  return (
    <Row>
      <Label style={{ marginTop: 50 }}>Users</Label>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Company Name</th>
            <th>Phone Number</th>
            {isAdmin && <th>Stripe Customer ID</th>}
            {isAdmin && <th>First Request</th>}
            {isAdmin && <th>Last Request</th>}
            {isAdmin && <th>Request Count</th>}
            {isAdmin && <th>Request per Minutes</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: any) => {
            const request = JSON.parse(requestCount[user._id] || '{}') || {}
            return (
              <tr key={user._id}>
                <td>{user.email}</td>
                <td>{user.firstName}</td>
                <td>{user.companyName}</td>
                <td>{user.phoneNumber}</td>
                {isAdmin && <td>{user.stripeCustomerId}</td>}
                {isAdmin && <td>{request.firstRequest}</td>}
                {isAdmin && <td>{request.lastRequest}</td>}
                {isAdmin && <td>{request.count}</td>}
                {isAdmin && <td>{requestPerMinutes(request)}</td>}
                <td>
                  <Button
                    className="btn-rounded "
                    color="success"
                    onClick={() => handleEdit(user._id)}
                  >
                    Edit
                  </Button>{' '}
                  {user.roles?.includes('ADMIN') ? (
                    <Button
                      className="btn-rounded "
                      color="warning"
                      onClick={() => removeAdmin(user._id)}
                    >
                      Remove Admin
                    </Button>
                  ) : (
                    <Button
                      className="btn-rounded "
                      color="info"
                      onClick={() => setAdmin(user._id)}
                    >
                      Set Admin
                    </Button>
                  )}{' '}
                  <Button
                    className="btn-rounded "
                    color="danger"
                    onClick={() => handleDelete(user._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            )
          })}
          <tr>
            <Row h="100" />
          </tr>
        </tbody>
      </Table>
      <Row h="100" />
    </Row>
  )
}

export default UsersTable
