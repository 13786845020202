import { APIClient } from './api_helper'

import * as url from './url_helper'

const api = new APIClient()

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user')
  if (user) return JSON.parse(user)
  return null
}

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data).catch((err) => {
    let message
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found'
          break
        case 500:
          message =
            'Sorry! something went wrong, please contact our support team'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = err[1]
          break
      }
    }
    throw message
  })
}

// Login Method
export const postFakeLogin = (data: any) =>
  api.create(url.POST_FAKE_LOGIN, data)

// postForgetPwd
export const postFakeForgetPwd = (data: any) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
export const postJwtProfile = (data: any) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data)

export const postFakeProfile = (data: any) =>
  api.create(url.POST_EDIT_PROFILE, data)

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data).catch((err: any) => {
    let message: any
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found'
          break
        case 500:
          message =
            'Sorry! something went wrong, please contact our support team'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = err[1]
          break
      }
    }
    throw message
  })
}

// Login Method
export const postJwtLogin = (data: any) =>
  api.create(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
export const postJwtForgetPwd = (data: any) => {
  return api.post(url.POST_FAKE_JWT_PASSWORD_FORGET, {
    email: data,
  })
}

export const resetPasswordApi = (data: any) => {
  return api.post('/reset-password', data)
}
export const sendVerificationEmail = (data: any) => {
  return api.post('/email-validation', data)
}

export const verfyEmail = (data: any) => {
  return api.post('/verify-email', data)
}
// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data)

export const sendSupportEmail = (data: any) => {
  return api.post('/support', data)
}
