import React, { useEffect } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// action
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import firebase from 'firebase/compat/app'
import { registerUser, apiError } from '../../slices/thunk'

// Add the Firebase products that you want to use
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
// redux

// import images
import profileImg from '../../assets/images/profile-img.png'
import logoImg from '../../assets/images/logoR.png'

const Register: React.FC<any> = () => {
  // meta title
  document.title = 'Register | ResellCorner'

  const navigate = useNavigate()

  const dispatch = useDispatch<any>()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      firstName: Yup.string().required('Please Enter Your Firstname'),
      lastName: Yup.string().required('Please Enter Your Lastname'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      // alert("La création de compte inactif pour l'instant")
      dispatch(registerUser(values, navigate))
    },
  })

  const selectProperties = createSelector(
    (state: any) => {
      return state.Account
    },
    (account) => {
      return {
        user: account.user,
        registrationError: account.registrationError,
        loading: account.loading,
        redirect: account.redirect,
      }
    }
  )

  const { user, redirect, registrationError } = useSelector(selectProperties)

  useEffect(() => {
    dispatch(apiError())
  }, [dispatch])

  useEffect(() => {
    if (registrationError) {
      setTimeout(() => {
        dispatch(apiError())
      }, 2000)
    }
  }, [registrationError])

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          {localStorage.getItem('disclaimer') && (
            <Alert
              style={{
                zIndex: 1000000,
                alignSelf: 'center',
                textAlign: 'center',
              }}
              color="danger"
            >
              <h1>{localStorage.getItem('disclaimer')}</h1>
            </Alert>
          )}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Inscription Plateforme ResellCorner
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Votre inscription est bien prise en compte ! Vous
                          allez recevoir un email de confirmation.
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      {Object.keys(validation.errors).length > 0
                        ? (Object.values(validation.errors) || [])?.map(
                            (error: any) => (
                              <Alert key={error} color="danger">
                                {error}
                              </Alert>
                            )
                          )
                        : null}

                      <div className="mb-3">
                        <Label className="form-label">Prénom</Label>
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="Entrer votre prénom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ''}
                          invalid={
                            !!(
                              validation.touched.firstName &&
                              validation.errors.firstName
                            )
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Nom</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Entrer votre nom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastName || ''}
                          invalid={
                            !!(
                              validation.touched.lastName &&
                              validation.errors.lastName
                            )
                          }
                        />
                        {validation.touched.lastName &&
                        validation.errors.lastName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lastName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Entrer votre email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            !!(
                              validation.touched.password &&
                              validation.errors.password
                            )
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Inscription
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          En continuant, vous acceptez les
                          <a
                            href="https://www.resellcorner.io/cgu"
                            target="_blank"
                            className="text-primary"
                            rel="noreferrer"
                          >
                            Conditions d'utilisation
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Vous avez déjà un compte ?{' '}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {' '}
                    Connexion
                  </Link>{' '}
                </p>
                <p>© {new Date().getFullYear()}ResellCorner.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Register
