// REGISTER
export const POST_FAKE_REGISTER = '/signup'

// LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login'
export const POST_FAKE_JWT_LOGIN = '/login'
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-password'
export const POST_FAKE_JWT_PASSWORD_FORGET = '/forgot-password'
export const SOCIAL_LOGIN = '/social-login'

// PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile'
export const POST_EDIT_PROFILE = '/post-fake-profile'
