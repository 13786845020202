import React from 'react'
import { Container, FormGroup, Input, Label } from 'reactstrap'

const Tuto = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <h1>Tuto ICI</h1>
      </Container>
    </div>
  )
}

export { Tuto }
