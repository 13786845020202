import React from 'react'
import { ButtonGroup, Card, CardFooter, Input } from 'reactstrap'
import * as data from 'data'

export const DisclaimerPage: React.FC<{}> = () => {
  const [text, setText] = React.useState('')
  React.useEffect(() => {
    data.user
      .get('disclaimer', { userAdmin: true } as any)
      .then((res) => {
        console.log({ res })
        if (res.message) {
          setText(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const sendDisclaimer = async () => {
    data.user
      .post('disclaimer', { message: text })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const showHideDisclaimer = async (show) => {
    data.user
      .post('disclaimer-show', { show })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div className="page-content">
      <Card>
        <h1>Entrer votre message à publier</h1>
        <Input
          onChange={(e) => {
            setText(e.target.value)
          }}
          value={text}
          type="textarea"
          name="text"
          id="exampleText"
        />
        <button onClick={sendDisclaimer} className="btn btn-primary">
          Sauvegarder
        </button>

        <CardFooter>
          <ButtonGroup>
            <button
              onClick={() => showHideDisclaimer(true)}
              className="btn btn-primary"
              style={{ marginRight: 20 }}
            >
              Publier
            </button>
            <button
              onClick={() => showHideDisclaimer(false)}
              className="btn btn-primary"
            >
              DePublier
            </button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  )
}
