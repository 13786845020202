import React from 'react'
import ReactDOM from 'react-dom/client'
import storage from 'redux-persist/lib/storage'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import './index.css'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import reportWebVitals from './reportWebVitals'
import App from './App'
import rootReducer from './slices'

const replacer = (_: string, value: Primitive | Date) =>
  value instanceof Date ? value.toISOString() : value

const reviver = (_: string, value: Primitive | Date) =>
  typeof value === 'string' &&
  value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    ? new Date(value)
    : value

export const encode = (toDeshydrate: any) =>
  JSON.stringify(toDeshydrate, replacer)

export const decode = (toRehydrate: any) => JSON.parse(toRehydrate, reviver)

const persistConfig = {
  key: 'root',
  transforms: [createTransform(encode, decode)],
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
})
const persistor = persistStore(store)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* <Elements stripe={stripePromise}> */}
        <App />
        {/* </Elements> */}
        <ToastContainer />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
