import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

// given a number return an array of numbers from 3 number before to 3 number after the given number betwen interval 1 and max
const getPaginationArray = (currentPage: number, max: number) => {
  const start = currentPage - 3 > 0 ? currentPage - 3 : 1
  const end = currentPage + 3 < max ? currentPage + 3 : max
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}
const CustomPagination = ({ pagesCount, currentPage, handlePageClick }) => {
  return (
    <Pagination>
      <PaginationItem disabled={currentPage <= 1}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>
      {[...getPaginationArray(currentPage, pagesCount)].map((page, i) => (
        <PaginationItem active={page === currentPage} key={page}>
          <PaginationLink onClick={(e) => handlePageClick(e, page)} href="#">
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage >= pagesCount}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, currentPage + 1)}
          previous
          href="#"
        />
      </PaginationItem>
    </Pagination>
  )
}

export { CustomPagination }
