import { createSelector } from '@reduxjs/toolkit'

type TRootState<T, S> = { [reducerName: string]: GenericStateType<T, S> }

export const selectorsFactory = <
  T extends { id: string },
  S extends { [key: string]: { [key: string]: string[] } | string },
>(
  reducerName: string
) => {
  // Helpers
  const getByIdCollectionHelper = (
    indexes: { [key: string]: T },
    ids: string[]
  ): T[] => {
    return ids.map((id) => indexes[id]).filter(Boolean)
  }
  const getId = (_: TRootState<T, S>, props: { id?: string }) => props?.id
  const getIds = (_: TRootState<T, S>, props: { ids: string[] }) => props.ids
  const getGroupIndexName = (
    _: TRootState<T, S>,
    { indexName }: { indexName: keyof S }
  ) => indexName
  const getGroupIndexObject = (
    _: TRootState<T, S>,
    {
      indexName,
      indexValue,
      limit = 100,
      offset = 0,
    }: {
      indexName: keyof S
      indexValue: ValueOf<S>
      limit?: number
      offset?: number
    }
  ) => ({
    indexName,
    indexValue,
    limit,
    offset,
  })

  // Selectors
  const state = (s: TRootState<T, S>) => s[reducerName]
  const getIndexes = createSelector([state], (s) => s.indexes)
  const getIdCollection = createSelector([state], (s) => s.allIds)
  const getAllCollection = createSelector([getIndexes], (indexes) =>
    Object.values(indexes)
  )

  const getOneById = createSelector([getIndexes, getId], (indexes, id) => {
    return (id && indexes[id]) || null
  })
  const getCollectionByIdCollection = createSelector(
    [getIndexes, getIds],
    getByIdCollectionHelper
  )
  const getGroupIndexes = createSelector(
    [state, getGroupIndexName],
    (s, indexName) =>
      s[indexName] || ({} as { [key in keyof S]: { [key: string]: string[] } })
  )
  const getIdCollectionByGroupIndex = createSelector(
    [state, getGroupIndexObject],
    (s: any, { indexName, indexValue }) =>
      s[indexName] ? s[indexName][indexValue] || [] : []
  )
  const getCollectionByGroupIndexIdCollection = createSelector(
    [getIndexes, getIdCollectionByGroupIndex],
    getByIdCollectionHelper
  )
  const isLoading = createSelector([state], (s) => s.isLoading)
  const isLoadingMore = createSelector([state], (s) => s.isLoadingMore)
  const isSubmitting = createSelector([state], (s) => s.isSubmitting)
  const hasMore = createSelector([state], (s) => s.hasMore)
  const lastUpdate = createSelector([state], (s) => s.lastUpdate)
  return {
    state,
    getIndexes,
    getIdCollection,
    getAllCollection,
    getOneById,
    getCollectionByIdCollection,
    getGroupIndexes,
    getIdCollectionByGroupIndex,
    getCollectionByGroupIndexIdCollection,
    isLoading,
    isLoadingMore,
    isSubmitting,
    hasMore,
    lastUpdate,
  }
}
